import {Component, Input, ViewEncapsulation} from '@angular/core';
import {DashboardComponent} from 'app/components/dashboard/dashboard.component';
import {ChartConfig} from '@app/services/charts/charts.service';

@Component({
  selector: 'app-chart',
  templateUrl: './chart.component.html',
  styleUrls: ['./chart.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class ChartComponent {
  @Input() dashboard: DashboardComponent;
  @Input() config: ChartConfig;
  @Input() dataGroups: {
    title: string,
    dataTracks: {
      [key: string]: any
    }[]
  }[] = [];

  constructor() {
  }
}
