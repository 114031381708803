import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpResponse} from '@angular/common/http';
import {Observable, ReplaySubject, Subject} from 'rxjs';
import {MockService} from './mock.service';
import {AuthService} from '../auth/auth.service';
import {environment} from 'environments/environment';
import {ApiLinkCollection, ApiQueryParams} from '@app/services/api/api.models';
import UriTemplate from 'uri-templates';

@Injectable()
export class ApiService {

  private subject: Subject<any>;

  private apiName: string;
  private apiVersion: string;
  private data = {};
  private links: ApiLinkCollection = {};

  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private mockService: MockService
  ) { }

  public initialize(): Subject<any> {

    if (!this.subject) {
      this.subject = new Subject();
      this.http.get(environment.api.url, { headers: this.getHeaderForJsonCalls(this.authService.isAuthenticated()) }).subscribe(this.subject);
      this.subject.subscribe({
        next: (res: any) => {
          this.links = res._links;
          this.apiName = res.name;
          this.apiVersion = res.version;
          console.log(`${this.apiName} ${this.apiVersion}`);
        },
        error: (err: any) => this.onError(err)
      });
    }

    return this.subject;
  }

  public get(endpoint = environment.api.url): Subject<any> {
    const subject = new ReplaySubject<any>(1),
          path = endpoint.replace(`${environment.api.url}/`, '');

    if (this.mockService.isMocked(path)) { // Mocked API
      console.warn('API access mocked:', path);
      this.mockService.get(path).subscribe(subject);

    } else { // Real API
      this.http.get(endpoint, { headers: this.getHeaderForJsonCalls() }).subscribe(subject);

    }

    subject.subscribe({
      next: (res: any) => {
        res = Object.assign(res, this.mockService.getPartial(path));
      },
      error: (err: any) => { this.onError(err); }
    });

    return subject;
  }

  public put(endpoint: string, body: any, authorize: boolean = true): Subject<any> {
    const subject = new Subject();

    this.http.put(endpoint, body, { headers: this.getHeaderForJsonCalls(authorize) }).subscribe(subject);

    subject.subscribe({
      error: (err: any) => { this.onError(err); }
    });

    return subject;
  }

  public post(endpoint: string, body: any, authorize: boolean = true): Subject<any> {
    const subject = new Subject();

    this.http.post(endpoint, body, { headers: this.getHeaderForJsonCalls(authorize) }).subscribe(subject);

    subject.subscribe({
      error: (err: any) => { this.onError(err); }
    });

    return subject;
  }

  public delete(endpoint: string, body: any, authorize: boolean = true): Subject<any> {
    const subject = new Subject();
    this.http.request('delete', endpoint, { body: body, headers: this.getHeaderForJsonCalls(authorize) }).subscribe(subject);

    subject.subscribe({
      error: (err: any) => { this.onError(err); }
    });

    return subject;
  }

  public getLink(key: string, params?: {[key: string]: string | number[]}): string {
    const link = this.links[key];
    let href = link ? link.href : null;
    if (link /* && link.templated */) {
      href = this.resolveLink(href, params);
    }
    return href;
  }

  public resolveLink(href: string, params?: ApiQueryParams): string {
    if (!params) {
      const templateStart = href.search('{');
      if (templateStart < 0) {
        return href;
      }
      return href.substring(0, templateStart);
    }

    const template = new UriTemplate(href);
    return template.fillFromObject(params);
  }

  public download(href: string): Observable<HttpResponse<Blob>> {
    return this.http.get(href, {
      headers: new HttpHeaders(this.getAuthorizationHeaders()),
      observe: 'response',
      responseType: 'blob'
    });
  }

  private getHeaderForJsonCalls(authorize: boolean = true): HttpHeaders {
    return new HttpHeaders({
      ...this.getAuthorizationHeaders(authorize),
      'Content-Type': 'application/json'
    });
  }

  private getAuthorizationHeaders(authorize: boolean = true): { [key: string]: string; } {
    return authorize ? {
      'Authorization': 'Bearer ' + this.authService.getToken()
    } : {};
  }

  private onError(error: string) {
    console.log(error);
  }
}
