import {Component, OnDestroy, OnInit} from '@angular/core';
import {AuthService} from '@app/services/auth/auth.service';
import {UserService} from '@app/services/user/user.service';
import {Subscription} from 'rxjs';
import {MenuItem} from "primeng/api";

@Component({
  selector: 'app-nav-account',
  templateUrl: './nav-account.component.html',
  styleUrls: ['./nav-account.component.scss']
})
export class NavAccountComponent implements OnInit, OnDestroy {
  readonly items: MenuItem[] = [
    {label: 'Change password', routerLink: '/account/password'},
    {label: 'Logout', command: () => this.logout()},
  ];
  private subscriptions: Subscription[] = [];

  constructor(
    private userService: UserService,
    private authService: AuthService,
  ) {
  }

  ngOnInit() {
    this.subscriptions.push(
      this.userService.initialize().subscribe({
        next: () => {
        }
      }),
    );
  }

  ngOnDestroy() {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  getUserName(): string {
    return this.userService.getUserDetail('name');
  }

  logout() {
    this.authService.logout();
  }
}
