export function SVGRendererSymbolPlus(x, y, w, h) {
  return ['M', x, y + h / 2, 'L', x + w, y + h / 2, 'M', x + w / 2, y, 'L', x + w / 2, y + h, 'z'];
}

export const AllGroups = {
  showInLegend: true,
};

export const StylesByGroupType = {
  HullTrack: {
    index: 0,
    legendIndex: 1000,
    lineWidth: 1,
    dashStyle: 'Dot',
    color: '#e3e3e3', // see $color-ci-6 for reference
    states: {
      hover: {
        lineWidthPlus: 0
      }
    }
  },
  HullEvent: {
    index: 2000,
    legendIndex: 2000,
    lineWidth: 0,
    marker: {
      enabled: true,
      radius: 4
    },
    states: {
      hover: {
        lineWidthPlus: 0
      }
    },
    tooltip: {
      pointFormatter: function () {
        return this.series.name;
      }
    }
  }
};

export const IndividualStylesByTrackId = {
  "FCREF": { // Hull Condition Fuel Consumption Reference
    index: 2,
    legendIndex: 1,
    marker: {
      symbol: 'circle',
      enabled: true,
      radius: 2,
      lineColor: '#003b79', // see $color-ci-1 for reference
      fillColor: '#003b79',
    },
    events: {
      legendItemClick: function (e) {
        e.preventDefault()
      }
    }
  },
  "FCREF LIMIT": { // Hull Condition Fuel Consumption Reference under limit
    index: 3,
    legendIndex: 2,
    lineWidth: 0,
    marker: {
      symbol: 'circle',
      enabled: true,
      radius: 2,
      lineColor: '#70ac2e', // see $color-ci-4 for reference
      fillColor: '#70ac2e',
    }
  },
  "FCREF WARN": { // Hull Condition Fuel Consumption Reference warning
    index: 4,
    legendIndex: 3,
    lineWidth: 0,
    marker: {
      symbol: 'circle',
      enabled: true,
      radius: 2,
      lineColor: '#faba00', // see $color-ci-3 for reference
      fillColor: '#faba00',
    }
  },
  "FCREF LATEST": { // Hull Condition Fuel Consumption Reference latest evaluation
    index: 5,
    legendIndex: 5,
    color: '#003b79', // see $color-ci-1 for reference
    lineWidth: 2,
    dashStyle: 'Line',
    marker: {
      enabled: false,
    }
  },
  "FCREF BASE": { // Hull Condition Fuel Consumption Baseline
    index: 1,
    legendIndex: 4,
    dashStyle: 'ShortDash',
    color: '#70ac2e', // see $color-ci-4 for reference
    lineWidth: 2,
    marker: {
      enabled: false,
    },
    enableMouseTracking: false,
  },
  "MARKER DRY": { // Hull Condition Last Dry Dock Date
    legendIndex: 1001,
    color: '#B70915',
    marker: {
      symbol: 'square'
    }
  },
  "MARKER CLEAN": { // Hull Condition Cleaning Date
    legendIndex: 1002,
    color: '#B70915',
    marker: {
      symbol: 'circle'
    }
  },
  "MARKER PROP": { // Hull Condition Propeller Polish Date
    legendIndex: 1003,
    color:
      '#A17800',
    marker: {
      symbol: 'plus',
      lineColor: null,
      lineWidth: 2
    }
  },
  "MARKER EXTRA1": { // Advanced PBCF Fitted
    legendIndex: 1004,
    color:
      '#4c75a1', // see $color-ci-1-b for reference
    marker: {
      symbol: 'diamond',
      lineColor: null,
      lineWidth: 2
    }
  },
  "MARKER EXTRA2": { // PBCF Retrofit, ITCH
    legendIndex: 1005,
    color:
      '#4c75a1', // see $color-ci-1-b for reference
    marker: {
      symbol: 'diamond',
      lineColor: null,
      lineWidth: 2
    }
  },
  "MARKER EXTRA3": { // PBCF Retrofit
    legendIndex: 1006,
    color:
      '#4c75a1', // see $color-ci-1-b for reference
    marker: {
      symbol: 'diamond',
      lineColor: null,
      lineWidth: 2
    }
  },
  "MARKER EXTRA4": { // Shipshave ITCH
    legendIndex: 1007,
    color:
      '#4c75a1', // see $color-ci-1-b for reference
    marker: {
      symbol: 'diamond',
      lineColor: null,
      lineWidth: 2
    }
  }
};
