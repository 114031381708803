
export class Dates {

  // Converts a utc timestamp to a custom date string:
  static utcTimestampToUtcString(timestamp: number): string {

    const date = new Date(timestamp),
          year = String(date.getUTCFullYear()),
          months = (date.getUTCMonth() > 8 ? '' : '0') + String(date.getUTCMonth() + 1),
          days = (date.getUTCDate() > 9 ? '' : '0') + String(date.getUTCDate()),
          hours = (date.getUTCHours() > 9 ? '' : '0') + String(date.getUTCHours()),
          minutes = (date.getUTCMinutes() > 9 ? '' : '0') + String(date.getUTCMinutes());

    return year + '-' + months + '-' + days + ' ' + hours + ':' + minutes + ' UTC';
  }

  // Converts a utc timestamp to a custom date string:
  static utcTimestampToLocalString(timestamp: number): string {

    const date = new Date(timestamp),
          year = String(date.getFullYear()),
          months = (date.getMonth() > 8 ? '' : '0') + String(date.getMonth() + 1),
          days = (date.getDate() > 9 ? '' : '0') + String(date.getDate()),
          hours = (date.getHours() > 9 ? '' : '0') + String(date.getHours()),
          minutes = (date.getMinutes() > 9 ? '' : '0') + String(date.getMinutes());

    return year + '-' + months + '-' + days + ' ' + hours + ':' + minutes + ' LC';
  }
}
