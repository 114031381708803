<footer class="align-items-center border-color-ci-6 border-top-1 color-ci-1-b flex flex-column sm:flex-row flex-wrap gap-inner m-3 p-default text-sm">
  <div>Fleet Online {{ version }}</div>
  <div>
    <a href="https://www.albis-mp.com" target="_blank" rel="noopener noreferrer">&copy; ALBIS MARINE PERFORMANCE</a>
  </div>
  <div>
    <a href="https://www.albis-mp.com/imprint" target="_blank" rel="noopener noreferrer">Imprint</a>
  </div>
  <div>
    <a href="https://www.albis-mp.com/data-protection" target="_blank" rel="noopener noreferrer">Privacy Policy</a>
  </div>
</footer>
