import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';

import {VesselGroupsService} from 'app/services/vessel-groups/vessel-groups.service';
import {ApiService} from 'app/services/api/api.service';
import {ChartsService} from '@app/services/charts/charts.service';
import {ChartRangeService} from '@app/services/ranges/chart-range.service';
import {DatePickerOptions} from '@app/components/date-picker/date-picker.component';
import {DateRange} from '@app/helpers/ranges';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})

export class DashboardComponent implements OnInit, OnDestroy {
  selectedVessel: any;
  selectedVesselDetails: any = {};
  dashboardPositions: { [key: string]: string; }[] = [];
  charts: any[];
  eventsRef: string;
  dashboard = this;
  edit: string;
  datePickerSelected: DateRange = undefined;
  datePickerOptions: DatePickerOptions = {
    opens: 'left'
  };

  private subscriptions: Subscription[] = [];
  private dataGroups: {
    title: string,
    dataTracks: {
      [key: string]: any
    }[]
  }[] = [];

  constructor(private apiService: ApiService,
              private vesselGroupsService: VesselGroupsService,
              private chartRangeService: ChartRangeService,
              private chartsService: ChartsService) {
  }

  ngOnInit() {
    this.apiService.initialize().toPromise().then(() => {
      this.apiService.get(this.apiService.getLink('fo:master-data')).toPromise().then(data => {
        this.dashboardPositions = data.dashboardPosition;

        this.subscriptions.push(
          this.chartRangeService.getRangeSubject().subscribe({
            next: (dateRange) => {
              this.datePickerSelected = dateRange;
              this.chartsService.setSelectedQueryPeriod({
                from: dateRange.start,
                to: dateRange.end
              });
            }
          }),

          this.vesselGroupsService.getSelectedVesselObservable().subscribe({
            next: (selectedVessel) => {
              this.selectedVessel = selectedVessel;
              this.updateVesselDetails();
            }
          }),

          this.chartsService.getCharts().subscribe({
            next: (charts) => {
              this.charts = charts;
            }
          })
        );
      });
    });
  }

  ngOnDestroy() {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  onDateRangeChanged(dateRange: DateRange) {
    this.chartRangeService.setRange(dateRange);
  }

  editWidget(position: string, edit = true) {
    if (!edit && position === this.edit) {
      this.edit = undefined;
    } else {
      this.edit = position;
    }
  }

  getSelectedVesselId() {
    return this.vesselGroupsService.getSelectedVesselId();
  }

  getChartByPosition(position: string): any {
    if (this.charts === undefined) {
      return undefined;
    }

    return this.charts && this.charts.find((chart) => {
      return chart.position === position;
    }) || null;
  }

  getDataGroups(): any {
    return this.dataGroups;
  }

  private updateDataGroups(dataTracks: { [key: string]: any }[]) {
    this.dataGroups = [];

    for (const track of dataTracks) {
      const title: string = track.dataGroup;
      let group = this.dataGroups.find((element) => {
        return element.title === title;
      });
      if (!group) {
        group = {title: title, dataTracks: []};
        this.dataGroups.push(group);
      }
      group.dataTracks.push(track);
    }

    this.dataGroups = this.dataGroups.sort((a: any, b: any) => {
      return (a.title > b.title) ? 1 : (a.title < b.title) ? -1 : 0;
    });

    for (const group of this.dataGroups) {
      group.dataTracks = group.dataTracks.sort((a: any, b: any) => {
        return (a.name > b.name) ? 1 : (a.name < b.name) ? -1 : 0;
      });
    }
  }

  private updateVesselDetails() {
    if (!this.selectedVessel) {
      return;
    }
    this.editWidget(undefined);
    this.charts = undefined;
    this.eventsRef = undefined;
    this.selectedVesselDetails = this.selectedVessel;

    const hrefDetails = this.apiService.resolveLink(this.selectedVessel._links.self.href);

    this.apiService.get(hrefDetails).toPromise().then(dataDetails => {

      if (this.selectedVessel && dataDetails.id === this.selectedVessel.id) {
        this.selectedVesselDetails = Object.assign({}, this.selectedVessel, dataDetails);
        // this.eventsRef = this.apiService.resolveLink(this.selectedVesselDetails._links['fo:vessel-alerts'].href);

        this.updateDataGroups(this.selectedVesselDetails._embedded.dataTracks || []);
      }
    });
  }
}
