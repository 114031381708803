import {Component, Inject, Input, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import Overlay, {Positioning} from 'ol/Overlay.js';
import Feature from 'ol/Feature';
import Icon from 'ol/style/Icon';
import Map from 'ol/Map';
import Point from 'ol/geom/Point';
import OSM from 'ol/source/OSM';
import Style from 'ol/style/Style';
import CircleStyle from 'ol/style/Circle';
import StyleFill from 'ol/style/Fill';
import TileLayer from 'ol/layer/Tile';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import View from 'ol/View';
import XYZ from 'ol/source/XYZ';
import {Coordinate, toStringXY} from 'ol/coordinate';
import {fromLonLat, toLonLat} from 'ol/proj';
import {defaults, Draw, MouseWheelZoom} from 'ol/interaction';
import {MapComponent} from "@app/components/map/map.component";
import {MapDrawComponent} from "@app/components/map/map-draw.component";
import {Geometry, GeometryCollection, LineString, MultiPoint} from "ol/geom";
import {SESSION_STORAGE, StorageService} from "ngx-webstorage-service";
import {unByKey} from "ol/Observable";
import {getLength} from "ol/sphere";
import {Fill, Stroke} from "ol/style";
import {EventsKey} from 'ol/events';
import ContextMenu from "ol-contextmenu";
import {never, primaryAction} from "ol/events/condition";
import {WKT} from "ol/format";
import {Trail, TrailResolution} from "@app/components/map/trail-ol";
import {getVectorContext} from "ol/render";
import {easeOut} from "ol/easing";
import {TileWMS} from "ol/source";
import TileState from "ol/TileState";
import {ImageTile} from "ol";
import LayerSwitcher from "ol-ext/control/LayerSwitcher"
import LayerGroup from "ol/layer/Group";
import {UserService} from "@app/services/user/user.service";
import {Subscription} from "rxjs";


@Component({
  selector: 'app-map-ol',
  templateUrl: './map-ol.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./map-ol.component.scss'],
  providers: [{provide: MapDrawComponent, useExisting: MapOlComponent}]
})
export class MapOlComponent extends MapDrawComponent implements OnInit, OnDestroy {
  @Input() parent: MapComponent;

  private readonly routesStorageKey = 'map.routes';
  private readonly overlayDefaultOffset = [0, -10];

  private map: Map;

  private chartWorldLoadFunction = (tile: ImageTile, src) => {
    const image = tile.getImage() as HTMLImageElement;
    fetch(src, {
      headers: {
        "Authorization": "Basic " + window.btoa('Albis' + ":" + 'ekXeQORe95')
      }
    })
      .then((response) => response.blob())
      .then((blob) => {
        const imageUrl = URL.createObjectURL(blob);
        image.addEventListener('load', () => URL.revokeObjectURL(imageUrl))
        image.src = imageUrl;
      })
      .catch(() => tile.setState(TileState.ERROR));
  }

  private readonly osmSource = new OSM({url: MapOlComponent.LAYER_BASE});
  private readonly osmLayer = new TileLayer({
    source: this.osmSource,
    // @ts-ignore
    displayInLayerSwitcher: false
  });
  private readonly osmLayerForChartWorld = new TileLayer({
    source: this.osmSource,
    // @ts-ignore
    displayInLayerSwitcher: false
  });

  private readonly baseChartWorldLayer = new TileLayer({
    source: new TileWMS({
      params: {
        'LAYERS': 'THE_CHART',
        'CSBOOL': '2',
        'CSVALUE': ',,,,,3,,,,'
      },
      url: 'https://wms-eval.chartworld.com/',
      tileLoadFunction: this.chartWorldLoadFunction
    }),
    maxZoom: 6,
    // @ts-ignore
    displayInLayerSwitcher: false
  });

  private readonly chartWorldDetailLayer = new TileLayer({
    source: new TileWMS({
      params: {
        'LAYERS': 'ENC',
        'CSBOOL': '4003',
        'CSVALUE': ',,,,,3,,,,',
      },
      url: 'https://wms-eval.chartworld.com/',
      tileLoadFunction: this.chartWorldLoadFunction,
      serverType: 'mapserver'
    }),
    minZoom: 6,
    // @ts-ignore
    displayInLayerSwitcher: false
  });

  private readonly chartWorldDetailNoLandLayer = new TileLayer({
    source: new TileWMS({
      params: {
        'LAYERS': 'ENC',
        'CSBOOL': '4003',
        'CSVALUE': ',,,,,3,,,,',
        'OBJECT': 'BUAARE,BUISGL,LNDARE,M_COVR,RAILWY,ROADWY',
        'OBJECTFILTERNEGATION': 'TRUE'
      },
      url: 'https://wms-eval.chartworld.com/',
      tileLoadFunction: this.chartWorldLoadFunction
    }),
    minZoom: 6,
    // @ts-ignore
    displayInLayerSwitcher: false
  });

  private readonly cioLayer = new TileLayer({
    source: new TileWMS({
      params: {
        'LAYERS': 'CIO',
        'CSBOOL': '2',
      },
      url: 'https://wms-eval.chartworld.com/',
      tileLoadFunction: this.chartWorldLoadFunction,
    }),
    // @ts-ignore
    title: "CIO",
    visible: false,
  });

  private readonly ecoLayer = new TileLayer({
    source: new TileWMS({
      params: {
        'LAYERS': 'ECO',
        'CSBOOL': '2',
      },
      url: 'https://wms-eval.chartworld.com/',
      tileLoadFunction: this.chartWorldLoadFunction,
    }),
    // @ts-ignore
    title: "ECO",
    visible: false,
  });

  private readonly seaMarkLayer = new TileLayer({
    source: new XYZ({url: MapOlComponent.LAYER_SEAMARK}),
    // @ts-ignore
    displayInLayerSwitcher: false
  });

  private readonly osmSeamarksLayerGroup = new LayerGroup({
    layers: [this.osmLayer, this.seaMarkLayer],
    // @ts-ignore
    title: "OSM+Seamarks",
    baseLayer: true
  });

  private readonly chartWorldLayerGroup = new LayerGroup({
    layers: [this.baseChartWorldLayer, this.chartWorldDetailLayer],
    // @ts-ignore
    title: "ChartWorld",
    baseLayer: true,
    visible: false,
  });

  private readonly chartWorldOsmLayerGroup = new LayerGroup({
    layers: [this.osmLayerForChartWorld, this.chartWorldDetailNoLandLayer],
    // @ts-ignore
    title: "ChartWorld+OSM",
    baseLayer: true,
    visible: false,
  })

  private readonly vesselLayer = new VectorLayer({
    source: new VectorSource(),
    updateWhileAnimating: true,
    updateWhileInteracting: true,
    // @ts-ignore
    displayInLayerSwitcher: false
  });
  private readonly selectedVesselLayer = new VectorLayer({
    source: new VectorSource(),
    updateWhileAnimating: true,
    updateWhileInteracting: true,
    // @ts-ignore
    displayInLayerSwitcher: false
  });
  private selectedVesselAnimateListener: EventsKey;
  private vesselOverlay: Overlay;

  private readonly trails: Trail[] = [];
  private trailOverlay: Overlay;

  private readonly routeStyle = new Style({
    fill: new Fill({
      color: '#003b79',
    }),
    stroke: new Stroke({
      color: '#003b79',
      lineDash: [10, 10],
      width: 2,
    }),
    image: new CircleStyle({
      radius: 5,
      stroke: new Stroke({
        color: '#003b79',
      }),
      fill: new Fill({
        color: '#003b79',
      }),
    }),
    geometry: (feature) => {
      const line = feature.getGeometry() as LineString;
      const multipoint = new MultiPoint(line.getCoordinates());
      return new GeometryCollection([line, multipoint]);
    },
  });
  private readonly routeLayer = new VectorLayer({
    source: new VectorSource({wrapX: false}),
    style: this.routeStyle,
    // @ts-ignore
    displayInLayerSwitcher: false
  });
  private routeDrawer: Draw;
  private readonly routeHelpOverlayOffset = [10, 10];
  private routeHelpOverlay: Overlay;
  private routeDistanceOverlay: Overlay;
  private routeOverlay: Overlay;

  private subscriptions: Subscription[] = [];

  private static radiantFromPoints(
    position: { longitude: number, latitude: number },
    nextPosition: { longitude: number, latitude: number }
  ): number {
    const dx = nextPosition.longitude - position.longitude,
      dy = nextPosition.latitude - position.latitude;
    let rotation = Math.atan2(dy, dx) - Math.PI / 2 + Math.PI * 2;
    rotation %= Math.PI * 2;
    return -rotation;
  }

  private static formatNauticalDistance(line: Geometry) {
    const length = getLength(line) / 1852;
    return Math.round(length * 100) / 100 + ' nm'
  };

  private static hideOverlay(overlay: Overlay) {
    overlay.setPosition(undefined);
  }

  constructor(@Inject(SESSION_STORAGE) private storage: StorageService,
              private userService: UserService) {
    super();
    this.trails.push(new Trail(TrailResolution.SixHours, 0, 5));
    this.trails.push(new Trail(TrailResolution.OneHour, 5, 8));
    this.trails.push(new Trail(TrailResolution.TenMinutes, 8, 10));
    this.trails.push(new Trail(TrailResolution.FiveMinutes, 10, 13));
    this.trails.push(new Trail(TrailResolution.All, 13, 30));
  }

  ngOnInit() {
    this.subscriptions.push(
      this.userService.initialize().subscribe({
        next: (user) => {
          if (user) {
            this.prepareMap(user.login === "Ardmore Admin")
          }
        }
      })
    );
  }

  ngOnDestroy() {
    if (this.selectedVesselAnimateListener) {
      unByKey(this.selectedVesselAnimateListener);
    }
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  startRoute() {
    this.routeDrawer = new Draw({
      source: this.routeLayer.getSource(),
      type: 'LineString',
      style: this.routeStyle,
      condition: primaryAction,
      freehandCondition: never,
    });
    this.map.addInteraction(this.routeDrawer);
    let listener: EventsKey | EventsKey[];
    this.routeDrawer.on('drawstart', (evt) => {
      this.createRouteDistanceOverlay();
      listener = evt.feature.getGeometry().on('change', (evt) => {
        this.routeDistanceOverlay.getElement().innerHTML = MapOlComponent.formatNauticalDistance(evt.target);
        this.routeDistanceOverlay.setPosition(evt.target.getLastCoordinate());
      });
    });
    this.routeDrawer.on('drawend', (event) => {
      this.map.removeOverlay(this.routeDistanceOverlay);
      this.routeDistanceOverlay = null;
      unByKey(listener);
    });
  }

  stopRoute() {
    if (this.routeDrawer) {
      this.map.removeInteraction(this.routeDrawer)
      this.routeDrawer = null;
      MapOlComponent.hideOverlay(this.routeHelpOverlay);
    }
  }

  drawVessels(vessels: any) {
    const features: Feature<Point>[] = [];
    for (const vessel of vessels) {
      const longitude = vessel.longitude ?? 0;
      const latitude = vessel.latitude ?? 0;
      if (Number.isNaN(longitude) || Number.isNaN(latitude)) {
        continue;
      }
      const heading = vessel.heading;
      const rotation = !Number.isNaN(heading) ? Math.round(heading * Math.PI / 180 * 100) / 100 : 0;
      const steaming = vessel.steaming;
      const symbol = typeof rotation === 'number' && steaming ? 'arrow' : 'dot';
      const color = vessel.alarmAlerts ? '#f01716' :
        vessel.warningAlerts ? '#ffba00' :
          vessel.infoAlerts ? '#73828e' : '#003c80';
      const feature = new Feature(new Point(fromLonLat([longitude, latitude])));
      const iconStyle = new Style({
        image: new Icon({
          anchor: [.5, .5],
          anchorXUnits: 'fraction',
          anchorYUnits: 'fraction',
          color: color,
          rotateWithView: true,
          rotation: rotation,
          src: `/assets/img/vessel-${symbol}.svg`
        })
      });

      feature.setId(vessel.id);
      feature.setStyle(iconStyle);
      features.push(feature);
    }
    this.vesselLayer.getSource().clear();
    this.vesselLayer.getSource().addFeatures(features);
  }

  drawSelectedVessel() {
    if (this.selectedVesselAnimateListener) {
      unByKey(this.selectedVesselAnimateListener);
    }

    if (!this.parent.selectedVessel) {
      return;
    }

    const start = Date.now();
    this.selectedVesselAnimateListener = this.selectedVesselLayer.on('postrender', (event) => this.animateSelectedVessel(start, event));
  }

  clearTrail() {
    this.trails.forEach(t => t.clear());
  }

  drawTrail() {
    if (!this.parent.selectedVessel || !this.parent.selectedVesselDetails) {
      return;
    }
    this.clearTrail();
    const positions = this.parent.selectedVesselDetails.positions;
    for (let i = positions.length - 2; i >= 0; --i) {
      const currentPosition = positions[i];
      const nextPosition = positions[i + 1];
      const projectedCurrentPosition = fromLonLat([currentPosition.longitude, currentPosition.latitude]);
      const projectedNextPosition = fromLonLat([nextPosition.longitude, nextPosition.latitude]);
      const rotation = MapOlComponent.radiantFromPoints(
        {longitude: projectedCurrentPosition[0], latitude: projectedCurrentPosition[1]},
        {longitude: projectedNextPosition[0], latitude: projectedNextPosition[1]}
      );
      const positionFeature = new Feature(new Point(projectedCurrentPosition));
      positionFeature.setId(currentPosition.measuredAt)
      const positionStyle = new Style({
        image: new Icon({
          color: '#003c80',
          rotation: rotation,
          rotateWithView: true,
          src: `/assets/img/vessel-trail.svg`
        })
      });
      positionFeature.setStyle(positionStyle);
      this.trails.forEach(t => t.addFeature(currentPosition, positionFeature)
      );
    }
    this.trails.forEach(t => t.commit());
  }

  moveTo(vessel: any) {
    if (!vessel) {
      return;
    }
    this.normalizeMap();
    let longitude = vessel.longitude;
    let latitude = vessel.latitude;
    if (!longitude) {
      longitude = 0;
    }
    if (!latitude) {
      latitude = 0;
    }
    if (Number.isNaN(longitude) || Number.isNaN(longitude)) {
      return;
    }
    const coordinate = fromLonLat([longitude, latitude]);
    const rotation = this.map.getView().getRotation();
    const cosAngle = Math.cos(-rotation);
    let sinAngle = Math.sin(-rotation);
    const rotX = coordinate[0] * cosAngle - coordinate[1] * sinAngle;
    const rotY = coordinate[1] * cosAngle + coordinate[0] * sinAngle;
    sinAngle = -sinAngle;
    const target = [rotX * cosAngle - rotY * sinAngle, rotY * cosAngle + rotX * sinAngle],
      distance = this.map.getView().getCenter()[0] - target[0];
    const projExtent = this.map.getView().getProjection().getExtent();
    if (Math.abs(distance) > projExtent[2]) {
      target[0] = target[0] + projExtent[2] * (distance < 0 ? -2 : 2);
    }
    this.map.getView().animate({
      center: target,
      duration: 1000
    }, () => {
      this.normalizeMap();
      this.drawSelectedVessel();
    })
  }

  private prepareMap(useChartWorld: boolean) {
    this.parent.setMapLoading(true);
    this.map = new Map({
      interactions: defaults({zoomDelta: 3}).extend([new MouseWheelZoom({constrainResolution: true, maxDelta: 3})]),
      target: document.getElementById('map'),
      layers: useChartWorld ? [
          this.osmSeamarksLayerGroup,
          this.chartWorldLayerGroup,
          this.chartWorldOsmLayerGroup,
          this.cioLayer,
          this.ecoLayer,
          this.selectedVesselLayer,
          ...this.trails.map(t => t.getLayer()),
          this.routeLayer,
          this.vesselLayer,
        ] :
        [
          this.osmSeamarksLayerGroup,
          this.selectedVesselLayer,
          ...this.trails.map(t => t.getLayer()),
          this.routeLayer,
          this.vesselLayer,
        ],
      view: new View({
        center: [0, 0],
        zoom: 0,
        enableRotation: false,
      }),
      controls: []
    });
    if (useChartWorld) {
      const switcher = new LayerSwitcher({reordering: false});
      this.map.addControl(switcher);
    }
    this.addContextMenu();
    this.addOverlays();
    this.restoreRoutes();
    this.attachMapHandlers();
    if (this.parent.selectedVessel) {
      if (this.storage.has(MapDrawComponent.ZOOM_STORAGE_KEY)) {
        this.map.getView().setZoom(this.storage.get(MapDrawComponent.ZOOM_STORAGE_KEY));
      }
      this.moveTo(this.parent.selectedVessel);
    }
    this.parent.setMapLoading(false);
  }

  private addContextMenu() {
    const contextmenu = new ContextMenu({
      width: 110,
      defaultItems: false,
    });
    contextmenu.on('beforeopen', (event) => {
      const feature = this.map.forEachFeatureAtPixel(event.pixel, (feature: Feature<any>) => {
        return feature;
      }, {hitTolerance: 5, layerFilter: layer => layer === this.routeLayer});
      if (feature) {
        contextmenu.clear();
        const deleteRoute = {
          text: 'Delete route',
          data: {route: feature},
          callback: (obj) => {
            this.routeLayer.getSource().removeFeature(obj.data.route);
          },
        };
        contextmenu.push(deleteRoute);
        contextmenu.enable();
      } else {
        contextmenu.disable();
      }
    });
    this.map.addControl(contextmenu);
  }

  private addOverlays() {
    this.vesselOverlay = this.createOverlay();
    this.map.addOverlay(this.vesselOverlay);
    this.trailOverlay = this.createOverlay();
    this.map.addOverlay(this.trailOverlay);
    this.routeHelpOverlay = this.createOverlay(false, 'top-left', this.routeHelpOverlayOffset);
    this.map.addOverlay(this.routeHelpOverlay);
    this.routeOverlay = this.createOverlay();
    this.map.addOverlay(this.routeOverlay);
  }

  private restoreRoutes() {
    if (this.storage.has(this.routesStorageKey)) {
      const featuresWkt = this.storage.get(this.routesStorageKey);
      const features = new WKT({splitCollection: true}).readFeatures(featuresWkt);
      this.routeLayer.getSource().addFeatures(features);
    }

    this.routeLayer.getSource().on('addfeature', (event) => {
      const features = this.routeLayer.getSource().getFeatures();
      const featuresWkt = new WKT().writeFeatures(features);
      this.storage.set(this.routesStorageKey, featuresWkt);
    })
    this.routeLayer.getSource().on('removefeature', (event) => {
      const features = this.routeLayer.getSource().getFeatures();
      const featuresWkt = new WKT().writeFeatures(features);
      this.storage.set(this.routesStorageKey, featuresWkt);
    })
  }

  private attachMapHandlers() {
    this.map.getViewport().addEventListener('contextmenu', (event) => {
      if (this.routeDrawer) {
        this.routeDrawer.removeLastPoint();
        this.createRouteDistanceOverlay();
      }
    });

    this.map.on('pointermove', (event) => {
      if (event.dragging) {
        return;
      }

      if (this.routeDrawer) {
        this.showRouteHelpOverlay(event.coordinate);
      }

      const overVessel = this.map.hasFeatureAtPixel(event.pixel, {layerFilter: layer => layer === this.vesselLayer});
      this.map.getTargetElement().style.cursor = overVessel ? 'pointer' : '';
      if (!overVessel) {
        MapOlComponent.hideOverlay(this.vesselOverlay);
      }

      const overTrail = this.map.hasFeatureAtPixel(event.pixel, {
        hitTolerance: 5, layerFilter: layer => this.trails.some(trail => trail.getLayer() === layer)
      });
      if (!overTrail) {
        MapOlComponent.hideOverlay(this.trailOverlay);
      }

      const overRoute = this.map.hasFeatureAtPixel(event.pixel, {
        hitTolerance: 5,
        layerFilter: layer => layer === this.routeLayer
      });
      if (!overRoute) {
        MapOlComponent.hideOverlay(this.routeOverlay);
      }

      this.map.forEachFeatureAtPixel(event.pixel, (feature: Feature<any>) => {
        this.showVesselOverlay(event.coordinate, feature.getId() as number);
      }, {layerFilter: layer => layer === this.vesselLayer});

      this.map.forEachFeatureAtPixel(event.pixel, (feature: Feature<any>) => {
        this.showTrailOverlay(event.coordinate, feature.getId() as number, overVessel);
      }, {hitTolerance: 5, layerFilter: layer => this.trails.some(trail => trail.getLayer() === layer)});

      this.map.forEachFeatureAtPixel(event.pixel, (feature: Feature<any>) => {
        this.showRouteOverlay(event.coordinate, feature, overVessel, overTrail);
      }, {hitTolerance: 5, layerFilter: layer => layer === this.routeLayer});
    });

    this.map.on('singleclick', (event: any) => {
      let vesselId: number | string = -1;
      this.map.forEachFeatureAtPixel(event.pixel, (feature: Feature<any>) => {
        vesselId = feature.getId();
      }, {layerFilter: layer => layer === this.vesselLayer});
      this.parent.onMapClicked(vesselId, 'map');
    });

    this.map.on('moveend', () => this.storage.set(MapDrawComponent.ZOOM_STORAGE_KEY, this.map.getView().getZoom()));
  }

  private createOverlay(show = false, positioning: Positioning = 'bottom-center', offset = this.overlayDefaultOffset) {
    const element = document.createElement('div');
    element.className = 'map-item-overlay';
    if (show) {
      element.classList.add('map-item-overlay-show')
    }
    return new Overlay({
      element: element,
      positioning: positioning,
      stopEvent: false,
      offset: offset,
    });
  }

  private showOverlay(overlay: Overlay, coordinate: Coordinate, content: string, offset = this.overlayDefaultOffset) {
    overlay.setOffset(offset);
    overlay.setPosition(coordinate);
    overlay.getElement().innerHTML = content;
    overlay.getElement().classList.add('map-item-overlay-show');
  }

  private showVesselOverlay(coordinate: Coordinate, vesselId: number,) {
    this.showOverlay(this.vesselOverlay, coordinate, this.parent.getVesselOverlayContent(vesselId));
  }

  private showTrailOverlay(coordinate: Coordinate, timestamp: number, overVessel: boolean) {
    const offset = overVessel ? [this.overlayDefaultOffset[0], this.overlayDefaultOffset[1] - this.vesselOverlay.getElement().offsetHeight] : this.overlayDefaultOffset;
    this.showOverlay(this.trailOverlay, coordinate, this.parent.getTrailOverlayContent(timestamp), offset);
  }

  private showRouteOverlay(coordinate: Coordinate, feature: Feature<any>, overVessel: boolean, overTrail: boolean) {
    let offset = overVessel ? [this.overlayDefaultOffset[0], this.overlayDefaultOffset[1] - this.vesselOverlay.getElement().offsetHeight] : this.overlayDefaultOffset;
    offset = overTrail ? [offset[0], offset[1] - this.trailOverlay.getElement().offsetHeight] : offset;
    const content = `<div>Lon / Lat: ${toStringXY(toLonLat(coordinate), 2)}</div>
                            <div>Distance: ${MapOlComponent.formatNauticalDistance(feature.getGeometry())}</div>`;
    this.showOverlay(this.routeOverlay, coordinate, content, offset);
  }

  private showRouteHelpOverlay(coordinate: Coordinate) {
    this.showOverlay(this.routeHelpOverlay, coordinate, 'Add waypoint: left-click<br>Undo: right-click<br>Finish: double-click<br>Delete: right-click route', this.routeHelpOverlayOffset);
  }

  private createRouteDistanceOverlay() {
    if (this.routeDistanceOverlay) {
      this.map.removeOverlay(this.routeDistanceOverlay);
    }
    this.routeDistanceOverlay = this.createOverlay(true);
    this.map.addOverlay(this.routeDistanceOverlay);
  }

  private animateSelectedVessel(start: number, event: any) {
    const duration = 1500;
    const elapsed = (event.frameState.time - start) % duration;
    const elapsedRatio = elapsed / duration;
    const radius = easeOut(elapsedRatio) * 32;
    const opacity = easeOut(1 - elapsedRatio);
    const style = new Style({
      image: new CircleStyle({
        radius: radius,
        fill: new StyleFill({color: `rgba(255, 255, 255, ${opacity})`}),
      }),
    });

    const longitude = this.parent.selectedVessel.longitude ?? 0;
    const latitude = this.parent.selectedVessel.latitude ?? 0;
    const point = new Point(fromLonLat([longitude, latitude]));

    const vectorContext = getVectorContext(event);
    vectorContext.setStyle(style);
    vectorContext.drawPoint(point);
    this.map.render();
  }

  private normalizeMap() {
    const center = this.map.getView().getCenter();
    const projExtent = this.map.getView().getProjection().getExtent();
    const modulo = projExtent[2];
    if (Math.abs(center[0]) > modulo) {
      // the range is from neg to pos, e.g. from -20 to 20.
      // to normalize to this range, we first add one side as an offset to get a range from 0 to 40 and
      // calculate the remainder for the whole range. then we subtract one side again for positive numbers.
      // since % is a remainder operation and not a real modulo (-6 % 40 = -6; -6 mod 40 = 34), we need to add one side for negative numbers.
      const a = (center[0] + modulo) % (modulo * 2);
      const newCenter = a < 0 ? a + modulo : a - modulo;
      this.map.getView().setCenter([newCenter, center[1]]);
    }
  }
}
