<div class="align-items-center flex flex-column gap-inner full-screen overflow-y-auto alignment">
  <div class="albis-logo">
    <p-card>
      <form class="flex flex-column gap-grid" [ngClass]="{ 'spinner': isLoading() }">
        <span class="p-float-label">
          <input pInputText class="w-full" id="username" name="username" [(ngModel)]="model.username" type="text" #username="ngModel"
                 required/>
          <label for="username">Username</label>
        </span>

        <span class="p-float-label">
          <p-password id="password" name="password" [feedback]="false" [(ngModel)]="model.password" required
                      [toggleMask]="true"
                      #password="ngModel"></p-password>
          <label for="password">Password</label>
        </span>

        <button pButton class="justify-content-center" (click)="login()" [disabled]="!model.username || !model.password" type="submit">Login</button>
      </form>
    </p-card>
  </div>

  <div>
    <a routerLink="/initiate-password-reset">Forgot password?</a>
  </div>

  <app-footer></app-footer>
</div>
