import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';

import { AuthService } from '../../services/auth/auth.service';
import { UserService } from '../../services/user/user.service';

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(
    private router: Router,
    private authService: AuthService,
    private userService: UserService
  ) { }

  canActivate(
    next?: ActivatedRouteSnapshot,
    state?: RouterStateSnapshot
  ): boolean {

    if (this.authService.isAuthenticated()
      && (! next.data.roles || this.userService.hasRole(next.data.roles))
      && (! next.data.permissions || this.userService.hasPermission(next.data.permissions))) {
      return true;
    } else {

      if (this.authService.isAuthenticated()) {
        this.router.navigate(['/']);
      } else {
        this.authService.logout();
      }

      return false;
    }
  }
}
