import {Component, OnDestroy, OnInit} from '@angular/core';
import {ApiService} from '@app/services/api/api.service';
import {Subscription} from 'rxjs';
import {HttpErrorResponse} from '@angular/common/http';
import {ActivatedRoute, Router} from '@angular/router';
import {PasswordChange} from '@app/services/api/api.models';

@Component({
  selector: 'app-password-reset',
  templateUrl: './password-reset.component.html',
  styleUrls: ['./password-reset.component.scss']
})
export class PasswordResetComponent implements OnInit, OnDestroy {
  submitting = false;
  displayMessage = false;
  message: string;

  private subscriptions: Subscription[] = [];
  private hrefResetPassword: string;

  private token: string;
  private login: string;

  constructor(private apiService: ApiService, private route: ActivatedRoute, private router: Router) {
  }

  ngOnInit(): void {
    this.subscriptions.push(
      this.apiService.initialize().subscribe({
        next: () => {
          this.hrefResetPassword = this.apiService.getLink('fo:reset-password');
        }
      }),
      this.route.queryParams.subscribe(params => {
        this.token = params.token;
        this.login = params.login;
      })
    );
  }

  ngOnDestroy(): void {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  submit(data: PasswordChange) {
    if (this.token.length <= 0 || this.login.length <= 0) {
      return;
    }
    this.submitting = true;
    this.apiService.put(this.hrefResetPassword, {
      login: this.login,
      token: this.token,
      ...data
    }, false).subscribe(() => this.onSuccess(),
      (error) => this.onError(error));
  }

  redirectToLogin() {
    this.router.navigate(['login']);
  }

  private onSuccess() {
    this.submitting = false;
    this.message = 'Password successfully reset!';
    this.displayMessage = true;
  }

  private onError(error: HttpErrorResponse) {
    this.submitting = false;
    if (error.status === 400) {
      this.message = error.error?.message ?? 'An error occurred, please try again later or contact Albis support.';
    } else if (error.status === 500) {
      this.message = 'An error occurred, please try again later or contact Albis support.';
    }
    this.displayMessage = true;
    console.log(error);
  }
}
