
export const partials: {[key: string]: any} = {

};

export const _meta = {

  '_links': {
    'self': {
      'href': 'http://localhost:4200/api/master-data',
      'title': 'Read master data (GET)',
      'type': 'GET'
    },
    'fo:clients': {
      'href': 'http://localhost:4200/api/clients',
      'title': 'Read all clients (GET)',
      'type': 'GET'
    },
    'fo:master-data': {
      'href': 'http://localhost:4200/api/master-data',
      'title': 'Read master data (GET)',
      'type': 'GET'
    },
    'fo:vessel-groups': {
      'href': 'http://localhost:4200/api/vessel-groups{?orderBy}',
      'title': 'Read all vessel groups, orderBy Name, State,  (GET)',
      'type': 'GET',
      'templated': true
    },
    'fo:data-tracks': {
      'href': 'http://localhost:4200/api/data-tracks',
      'title': 'Read all data tracks (GET)',
      'type': 'GET'
    },
    'fo:vessel-group-create': {
      'href': 'http://localhost:4200/api/vessel-groups',
      'title': 'Create vessel group (POST)',
      'type': 'POST'
    },
    'fo:users': {
      'href': 'http://localhost:4200/api/users',
      'title': 'Read all users (GET)',
      'type': 'GET'
    },
    'fo:user-create': {
      'href': 'http://localhost:4200/api/users',
      'title': 'Create user (POST)',
      'type': 'POST'
    },
    'fo:current-user': {
      'href': 'http://localhost:4200/api/current-user',
      'title': 'Read current user (GET)',
      'type': 'GET'
    },
    'fo:vessels': {
      'href': 'http://localhost:4200/api/vessels',
      'title': 'Read all vessels (GET)',
      'type': 'GET'
    },
    'fo:entry-point': {
      'href': 'http://localhost:4200/api',
      'title': 'Entry Point (GET)',
      'type': 'GET'
    },
    'curies': [
      {
        'href': 'http://localhost:4200/docs/{rel}.html',
        'name': 'fo',
        'templated': true
      }
    ]
  }
};
