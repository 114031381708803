
import { Pipe, PipeTransform } from '@angular/core';
import { Dates } from 'app/helpers/dates';

@Pipe({
    name: 'utcString'
})

export class UtcStringPipe implements PipeTransform {
  transform(timestamp: number): string {
    return Dates.utcTimestampToUtcString(timestamp);
  }
}
