import {Inject, Injectable, Renderer2} from '@angular/core';
import {Subject} from "rxjs";
import {DOCUMENT} from "@angular/common";

declare function windyInit(options, callback);

@Injectable()
export class WindyService {
  private readonly options = {
    key: '2W63R3T2FpIg1EBJ8ZraIUFBQUh1kjPl',
    particlesAnim: 'on',
    verbose: false,
  };

  private downloadSubject: Subject<void>;
  private subject: Subject<any>;
  private error: any;

  constructor(@Inject(DOCUMENT) private document: Document) {
  }

  public initialize(renderer: Renderer2): Subject<any> {
    if (!this.downloadSubject) {
      this.downloadSubject = new Subject<void>();
      this.subject = new Subject<any>();

      this.downloadSubject.subscribe({
        next: () => {
          windyInit(this.options, windyAPI => {
            this.subject.next(windyAPI);
          });
        },
        error: (error) => {
          this.error = error;
          this.subject.error(error)
        }
      })

      const scriptElement = this.attachScriptElement(renderer, "https://api.windy.com/assets/map-forecast/libBoot.js");
      scriptElement.onload = () => {
        this.downloadSubject.next();
      };
      scriptElement.onerror = () => {
        this.downloadSubject.error('Could not load library');
      }

    } else if (!this.error) {
      // calling windyInit is deprecated but the only easy way to initialize it after using other routes
      windyInit(this.options, windyAPI => {
        this.subject.next(windyAPI);
      });
    } else {
      this.subject.error(this.error);
    }

    return this.subject;
  }

  private attachScriptElement(renderer: Renderer2, src: string): HTMLScriptElement {
    const script = renderer.createElement('script');
    script.type = 'text/javascript';
    script.src = src;
    renderer.appendChild(this.document.body, script);
    return script;
  }
}
