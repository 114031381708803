import {Injectable} from '@angular/core';
import {ReplaySubject} from 'rxjs';
import {ApiService} from '../api/api.service';

@Injectable()
export class VesselGroupsService {

  public loading = true;

  private subject: ReplaySubject<any>;
  private selectedVesselSubject: ReplaySubject<any>;
  private vesselGroups: any = [];
  private vessels: any = [];
  private selectedVesselId: number;

  constructor(
    private apiService: ApiService
  ) {
    this.selectedVesselSubject = new ReplaySubject<any>(1);
  }

  public initialize(): ReplaySubject<any> {

    if (!this.subject) {
      this.subject = new ReplaySubject(1);

      this.apiService.initialize().toPromise().then(() => {
        this.update();
      });
    }
    return this.subject;
  }

  public getVessels(): any {
    return this.vessels;
  }

  public getVesselGroups(): any {
    return this.vesselGroups;
  }

  public getSelectedVesselId(): number {
    return this.selectedVesselId;
  }

  public getSelectedVessel(): any {
    return this.vessels.find(vessel => vessel.id === this.selectedVesselId);
  }

  public getSelectedVesselObservable(): ReplaySubject<any> {
    return this.selectedVesselSubject;
  }

  public setSelectedVesselId(id: number) {
    this.selectedVesselId = id;
    this.selectedVesselSubject.next(this.getSelectedVessel());
  }

  private update(): void {
    const href = this.apiService.getLink('fo:vessel-groups');

    this.loading = true;
    this.apiService.get(href).toPromise().then(groups => {
      this.vesselGroups = groups._embedded['fo:vessel-groups'];
      this.vessels = [];
      for (const group of this.vesselGroups) {
        this.vessels = this.vessels.concat(group._embedded['fo:vessels']);
      }
      this.selectedVesselSubject.next(this.getSelectedVessel());
      this.subject.next(this.vessels);
      this.loading = false;
    });
  }
}
