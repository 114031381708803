<div class="content" [ngClass]="{ 'spinner': charts === undefined && getSelectedVesselId() }">
  <ng-template #selectVessel>
    <h1>Please select a vessel</h1>
  </ng-template>

  <div class="container" *ngIf="getSelectedVesselId(); else selectVessel">
    <div class="align-items-center flex flex-wrap gap-inner justify-content-between mb-5">
      <h1 class="m-0">{{ selectedVessel ? selectedVessel.name : '' }}</h1>
      <app-date-picker *ngIf="datePickerSelected !== undefined" (dateRange)="onDateRangeChanged($event)" [label]="''"
                       [options]="datePickerOptions" [selected]="datePickerSelected">
      </app-date-picker>
    </div>

    <div class="chart-row" *ngIf="selectedVessel">
      <div class="chart" *ngFor="let position of dashboardPositions">
        <app-chart *ngIf="charts !== undefined" [config]="getChartByPosition(position.name)" [dashboard]="dashboard"
                   [dataGroups]="getDataGroups()">
        </app-chart>
      </div>
    </div>
  </div>
</div>
