import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import * as Highcharts from 'highcharts';
import {SVGRendererSymbolPlus} from '@app/components/hull-condition/hull-condition-tracks/series-builder-config';
import {SeriesBuilderService} from '@app/components/hull-condition/hull-condition-tracks/series-builder.service';

@Component({
  selector: 'app-hull-condition-tracks',
  templateUrl: './hull-condition-tracks.component.html',
  styleUrls: ['./hull-condition-tracks.component.scss'],
  providers: [SeriesBuilderService]
})
export class HullConditionTracksComponent implements OnChanges {
  @Input() hullConditionTracks: any | undefined = undefined;
  @Input() yAxisName: string = '';

  Highcharts = Highcharts;
  highchartOptions: any = {};

  private chartConfig = {
    chart: {
      type: 'line',
      zoomType: 'x',
      style: {
        fontFamily: 'Heebo',
      }
    },
    legend: {
      itemStyle: {
        fontWeight: 'bold'
      },
      itemHiddenStyle: {
        color: '#cccccc',
        textDecoration: 'none'
      }
    },
    title: {
      text: ''
    },
    tooltip: {
      borderWidth: 1
    },
  };

  private yAxisConfig = {
    yAxis: {
      title: {
        text: '',
        style: {
          'font-weight': 'bold',
          fill: '#000000',
          color: '#000000'
        }
      },
      labels: {
        style: {
          color: '#666666',
          // fontSize: '1.1rem'
        }
      },
      lineColor: '#ccd6eb',
      lineWidth: 1,
      max: undefined
    }
  };

  private xAxisConfig = {
    xAxis: {
      title: {
        text: ''
      },
      labels: {
        style: {
          color: '#666666',
          // fontSize: '1.1rem'
        }
      },
      lineColor: '#ccd6eb',
      type: 'datetime',
      gridLineWidth: 1,
      tickColor: '#ccd6eb'
    }
  };

  constructor(private seriesBuilderService: SeriesBuilderService) {
    // Define a custom plus symbol path
    Highcharts.SVGRenderer.prototype.symbols.plus = SVGRendererSymbolPlus;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.hullConditionTracks) {
      if (changes['yAxisName']) {
        this.yAxisConfig.yAxis.title.text = changes['yAxisName'].currentValue;
      }
      if (changes['hullConditionTracks']) {
        this.handleHulConditionTracksChange(changes['hullConditionTracks'].currentValue);
      }
    }
  }

  private handleHulConditionTracksChange(tracks) {
    this.yAxisConfig.yAxis.max = this.getYAxisMaxFromTracks(tracks);
    this.highchartOptions = tracks ?
      {
        ...this.chartConfig,
        ...this.yAxisConfig,
        ...this.xAxisConfig,
        ...this.getSeriesFromTracks(tracks)
      }
      : undefined;
  }

  private getSeriesFromTracks(tracks) {
    return {
      series: tracks.map((track) => {
        return this.seriesBuilderService.getSerieFromTrack(track);
      })
    };
  };

  private getYAxisMaxFromTracks(tracks) {
    if (Array.isArray(tracks) === false) {
      return undefined;
    }

    const fcrRefBaseTrack = tracks.find((track) => {
      return track.abbreviation === 'FCREF BASE';
    })
    if (!fcrRefBaseTrack) {
      return undefined;
    }

    const fcrRefBaseTrackLatestValue = fcrRefBaseTrack.values.slice(-1)[0].value;

    return Math.round(fcrRefBaseTrackLatestValue * 1.7);
  };
}
