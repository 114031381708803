import {Component, OnDestroy, OnInit} from '@angular/core';
import {ApiService} from '@app/services/api/api.service';
import {Subscription} from 'rxjs';
import {HttpErrorResponse} from '@angular/common/http';

@Component({
  selector: 'app-initiate-password-reset',
  templateUrl: './initiate-password-reset.component.html',
  styleUrls: ['./initiate-password-reset.component.scss']
})
export class InitiatePasswordResetComponent implements OnInit, OnDestroy {

  submitting = false;

  username = '';

  displayMessage = false;
  message = '';

  private subscriptions: Subscription[] = [];
  private hrefResetPassword: string;

  constructor(private apiService: ApiService) {}

  ngOnInit(): void {
    this.subscriptions.push(
      this.apiService.initialize().subscribe({
        next: () => {
          this.hrefResetPassword = this.apiService.getLink('fo:reset-password');
        }
      })
    );
  }

  ngOnDestroy(): void {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  submit() {
    this.submitting = true;
    this.apiService.delete(this.hrefResetPassword, {
      loginOrEmail: this.username.trim()
    }, false).subscribe(() => this.onSuccess(),
        (error) => this.onError(error));
  }

  hasUsername() {
    return this.username.trim().length > 0;
  }

  private onSuccess(): void {
    this.username = '';
    this.submitting = false;
    this.message = 'Please check your email!';
    this.displayMessage = true;
  }

  private onError(error: HttpErrorResponse) {
    this.submitting = false;
    if (error.status === 400) {
      this.message = error?.message ?? 'An error occurred, please try again later or contact Albis support';
    } else if (error.status === 500) {
      this.message = 'An error occurred, please try again later or contact Albis support';
    }
    console.log(error);
    this.displayMessage = true;
  }
}
