import {Component} from '@angular/core';
import {AuthService} from './services/auth/auth.service';

@Component({
  selector: 'app-fo',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent {
  showVesselNav = true;
  authService: AuthService;

  constructor(authService: AuthService) {
    this.authService = authService;
    if (window.innerWidth < 500) {
      this.showVesselNav = false;
    }
  }
}
