import { Injectable } from '@angular/core';
import { ApiService } from '@app/services/api/api.service';
import { Observable, Subject } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { HttpResponse } from '@angular/common/http';
import { saveAs } from 'file-saver';
import { File } from '@app/services/api/api.models';

@Injectable({
  providedIn: 'root'
})
export class FilesService {

  constructor(
    private apiService: ApiService
  ) {
  }

  public downloadFile(file: File): Observable<File> {
    const subject = new Subject();
    this.apiService.download(this.getDownloadLinkForFile(file))
      .pipe(finalize(() => {
        subject.next(file);
        subject.complete();
      }))
      .subscribe((response: HttpResponse<Blob>) => {
        this.save(response);
      });
    return <Observable<File>>subject.asObservable();
  }

  public downloadFiles(files: File[], downloadHref: string): Observable<any> {
    const subject = new Subject();
    const fileNames = files.map(file => file.name);
    const hrefDownload = this.apiService.resolveLink(downloadHref, {filename: fileNames});
    this.apiService.download(hrefDownload)
      .pipe(finalize(() => {
        subject.next({});
        subject.complete();
      })).subscribe({
      next: (response: HttpResponse<Blob>) => {
        this.save(response);
      }
    });
    return <Observable<any>>subject.asObservable();
  }


  private getDownloadLinkForFile(file: File): string {
    return file._links[ 'fo:download' ].href;
  }

  private save(response: HttpResponse<Blob>): void {
    saveAs(new File([response.body], this.getFilenameFromResponse(response), { type: response.body.type }));
  }

  private getFilenameFromResponse(response: HttpResponse<Blob>): string {
    const disposition: string = response.headers.get('content-disposition');
    const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
    let filename = 'download';

    if (disposition && disposition.indexOf('attachment') !== -1) {
      const matches = filenameRegex.exec(disposition);
      if (matches != null && matches[1]) {
        filename = matches[1].replace(/['"]/g, '');
      }
    }
    return filename;
  }

}
