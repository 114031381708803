import {Injectable} from '@angular/core';
import {BehaviorSubject, ReplaySubject, Subject} from 'rxjs';
import {ApiQueryParams, Chart, Vessel} from '../api/api.models';
import {ApiService} from '@app/services/api/api.service';
import {VesselGroupsService} from '@app/services/vessel-groups/vessel-groups.service';

export type UnixTimeStamp = number;

// Suppress 'use interface instead of type' warning. When using an interface UnixTimestapmp is not correctly duck-typed to number.
// eslint-disable-next-line
export type UnixTimeStampPeriod = {
  from: UnixTimeStamp;
  to: UnixTimeStamp;
};

export interface ChartConfig extends Chart {
  loading: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class ChartsService {
  private chartsSubject: Subject<Chart[]> = new ReplaySubject<Chart[]>(1);
  private chartWithDataSubjects: Map<string, BehaviorSubject<ChartConfig>> = new Map<string, BehaviorSubject<ChartConfig>>();

  private selectedVessel: Vessel;
  private selectedQueryPeriod: UnixTimeStampPeriod;

  constructor(
    private apiService: ApiService,
    private vesselGroupsService: VesselGroupsService
  ) {
    this.apiService.initialize().toPromise().then(() => {
      this.vesselGroupsService.getSelectedVesselObservable().subscribe({
        next: (selectedVessel) => {
          this.setSelectedVessel(selectedVessel);
        }
      });
    });
  }

  public setSelectedVessel(selectedVessel: Vessel) {
    if (selectedVessel !== this.selectedVessel) {
      this.chartsSubject.next([]);
      this.completeAndRemoveChartsWithData();
      this.selectedVessel = selectedVessel;
      this.updateCharts();
    }
  }

  public setSelectedQueryPeriod(selectedQueryPeriod: UnixTimeStampPeriod) {
    if (!this.queryPeriodEquals(selectedQueryPeriod, this.selectedQueryPeriod)) {
      this.selectedQueryPeriod = selectedQueryPeriod;
      this.updateChartsWithData();
    }
  }

  public getCharts(): Subject<Chart[]> {
    return this.chartsSubject;
  }

  public getChartWithData(chartConfig: ChartConfig): BehaviorSubject<ChartConfig> {
    const key = this.getKeyFromChart(chartConfig);
    if (this.chartWithDataSubjects.has(key)) {
      return this.chartWithDataSubjects.get(key);
    }

    const chartWithDataSubject = new BehaviorSubject<ChartConfig>(chartConfig);
    const queryDurationParameters = this.getQueryDurationParameters();
    this.fetchChartWithData(queryDurationParameters, chartWithDataSubject);
    this.chartWithDataSubjects.set(key, chartWithDataSubject);
    return chartWithDataSubject;
  }

  public updateChart(chart: Chart, editModel): Subject<any> {

    // TODO: we need cachebreaking here (the diagram is copied to all other vessels!).
    // After toggle the vessel the api cache will deliver old state.
    const queryDurationParameters = this.getQueryDurationParameters();
    const put = this.apiService.resolveLink(chart._links[ 'edit' ].href, queryDurationParameters);
    const apiResultSubject = this.apiService.put(put, editModel);

    apiResultSubject.toPromise().then(chartWithData => {
      const key = this.getKeyFromChart(chart);
      this.chartWithDataSubjects.get(key).next(chartWithData);
    });

    return apiResultSubject;
  }


  private getKeyFromChart(chart: Chart): string {
    return chart._links.self.href;
  }

  private updateCharts() {
    if (!(this.selectedVessel)) {
      this.chartsSubject.next([]);
      return;
    }

    const hrefCharts = this.apiService.resolveLink(this.selectedVessel._links[ 'fo:charts' ].href);
    this.apiService.get(hrefCharts).toPromise().then(data => {
      this.chartsSubject.next(data._embedded[ 'fo:charts' ] || []);
    });
  }

  private updateChartsWithData() {
    if (this.selectedQueryPeriod) {
      const queryDurationParameters = this.getQueryDurationParameters();
      this.chartWithDataSubjects.forEach(this.fetchChartWithData.bind(this, queryDurationParameters));
    }
  }

  private completeAndRemoveChartsWithData() {
    this.chartWithDataSubjects.forEach((subject: BehaviorSubject<Chart>) => {
      subject.complete();
    });
    this.chartWithDataSubjects.clear();
  }

  private fetchChartWithData(params: ApiQueryParams, chartWithDataSubject: BehaviorSubject<ChartConfig>) {
    const currentChart = chartWithDataSubject.getValue();

    chartWithDataSubject.next({...currentChart, loading: true});

    const self = this.apiService.resolveLink(currentChart._links.self.href, params);
    this.apiService.get(self).toPromise()
      .then((chart: Chart) => {
        const chartConfig: ChartConfig = {...chart, loading: false};
        chartWithDataSubject.next(chartConfig);
      });
  }

  private getQueryDurationParameters() {
    if (this.selectedQueryPeriod) {
      return {
        from: [this.selectedQueryPeriod.from * 1000],
        to: [this.selectedQueryPeriod.to * 1000]
      };
    }

    return null;
  }

  private queryPeriodEquals(a: UnixTimeStampPeriod, b: UnixTimeStampPeriod) {
    return a && b && a.from === b.from && a.to === b.to;
  }

}
