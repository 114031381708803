import {Component, OnDestroy, OnInit} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {Subscription} from 'rxjs';
import {VesselGroupsService} from '@app/services/vessel-groups/vessel-groups.service';
import {VESSEL_AWARE_ROUTEPARTS} from '@app/core/constants/constants';
import {UserService} from '@app/services/user/user.service';
import {MenuItem} from "primeng/api";

@Component({
  selector: 'app-nav-main',
  templateUrl: './nav-main.component.html',
  styleUrls: ['./nav-main.component.scss']
})
export class NavMainComponent implements OnInit, OnDestroy {
  private subscriptions: Subscription[] = [];
  private activeVesselId: string | null = null;
  private items: MenuItem[] = [
    {label: 'Fleet Map', routerLink: `/map`},
    {label: 'Fleet Reports', routerLink: '/fleetreports', visible: false},
    {label: 'Vessel Sensor Data', routerLink: '/details'},
    {label: 'Vessel Reports', routerLink: '/reports'},
    {label: 'Vessel Hull Condition', routerLink: '/hull', visible: false},
  ];

  constructor(
    private router: Router,
    private userService: UserService,
    private vesselGroupsService: VesselGroupsService
  ) {
  }

  ngOnInit() {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.onUrlUpdate(this.router.url);
      }
    });
    this.onUrlUpdate(this.router.url);

    this.subscriptions.push(
      this.userService.initialize().subscribe({
        next: () => {
          this.updatePermissionsOnUserChange();
        }
      }),
    );
  }

  ngOnDestroy() {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  getItems() {
    return this.items;
  }

  private onUrlUpdate(url: string) {
    const newVesselId = this.getVesselId(url);
    if (newVesselId != this.activeVesselId) {
      this.activeVesselId = this.getVesselId(url);
      const vessel = this.activeVesselId ? this.activeVesselId : '';
      this.items[0].routerLink = '/map/' + vessel;
      this.items[2].routerLink = '/details/' + vessel;
      this.items[3].routerLink = '/reports/' + vessel;
      this.items[4].routerLink = '/hull/' + vessel;
      this.items = this.items.map((item) => item);
    }
  }

  private getVesselId(url): string {
    const segments = url.replace(/^\/+/g, '').split('/');
    const selectedVesselId = this.vesselGroupsService.getSelectedVesselId();
    let vesselId = selectedVesselId ? selectedVesselId.toString() : '';
    if (segments.length > 1 && VESSEL_AWARE_ROUTEPARTS.includes(segments[0])) {
      vesselId = segments[1];
    }
    return vesselId;
  }

  private updatePermissionsOnUserChange(): void {
    this.items[1].visible = this.userService.hasPermission('fo:reports');
    this.items[4].visible = this.userService.hasPermission('fo:hull-condition');
    this.items = this.items.map((item) => item);
  }
}
