<div class="align-items-center flex flex-column gap-inner full-screen overflow-y-auto alignment">
  <div class="albis-logo">
    <p-card>
      <form class="flex flex-column gap-grid" [ngClass]="{ 'spinner': submitting }">
        <p>
          Please fill in your username or email address. You will receive an email with further instructions.
        </p>

        <span class="p-float-label">
          <input pInputText class="w-full" id="username" name="username" [disabled]="submitting" [(ngModel)]="username"
                 type="text"
                 required/>
          <label for="username">Username or email address</label>
        </span>

        <button pButton class="justify-content-center" (click)="submit()" [disabled]="submitting || !hasUsername()"
                type="submit">Reset password
        </button>
      </form>
    </p-card>

    <p-dialog header="" [(visible)]="displayMessage" [modal]="true" [closable]="false">
      <p>{{ message }}</p>
      <ng-template pTemplate="footer">
        <p-button (click)="displayMessage=false" label="Ok"></p-button>
      </ng-template>
    </p-dialog>

  </div>

  <div>
    <a routerLink="/login">Login</a>
  </div>

  <app-footer></app-footer>
</div>
