<div *ngIf="config">
  <app-chart-x-y *ngIf="config.type === 'XyChart'" [config]="config" [dashboard]="dashboard" [dataGroups]="dataGroups">
  </app-chart-x-y>

  <app-chart-y-t *ngIf="config.type === 'YtChart'" [config]="config" [dashboard]="dashboard" [dataGroups]="dataGroups">
  </app-chart-y-t>
</div>

<div class="chart-create" *ngIf="!config">
  <div class="chart-title">Create Chart</div>
  <div class="chart-content"></div>
</div>
