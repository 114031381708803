<div class="content">
  <ng-template #selectVessel>
    <h1>Please select a vessel</h1>
  </ng-template>

  <div class="h-full" *ngIf="getSelectedVesselId(); else selectVessel">
    <h1>{{ getVesselName() }}</h1>
    <app-reports-panel [reportsService]="reportsService">
    </app-reports-panel>
  </div>
</div>
