import {RouterModule, Routes} from '@angular/router';

import {LoginComponent} from './components/login/login.component';
import {
  InitiatePasswordResetComponent
} from './components/password/initiate-password-reset/initiate-password-reset.component';
import {DashboardComponent} from './components/dashboard/dashboard.component';
import {FleetReportsComponent} from './components/reports/fleet-reports/fleet-reports.component';
import {VesselReportsComponent} from './components/reports/vessel-reports/vessel-reports.component';
import {NotFoundComponent} from './components/not-found/not-found.component';
import {AuthGuard} from './guards/auth/auth.guard';
import {LoginGuard} from './guards/login/login.guard';
import {ChangePasswordComponent} from '@app/components/password/change-password/change-password.component';
import {PasswordResetComponent} from '@app/components/password/password-reset/password-reset.component';
import {HullConditionComponent} from '@app/components/hull-condition/hull-condition.component';
import {MapComponent} from "@app/components/map/map.component";

const routes: Routes = [

  {
    path: 'map/:vessel',
    component: MapComponent,
    canActivate: [AuthGuard],
  }, {
    path: 'details/:vessel',
    component: DashboardComponent,
    canActivate: [AuthGuard]
  }, {
    path: 'reports/:vessel',
    component: VesselReportsComponent,
    canActivate: [AuthGuard]
  }, {
    path: 'hull/:vessel',
    component: HullConditionComponent,
    canActivate: [AuthGuard],
    data: { permissions: ['fo:hull-condition']}
  }, {
    path: 'fleetreports',
    component: FleetReportsComponent,
    canActivate: [AuthGuard],
    data: { roles: ['ClientAdmin'] }
  }, {
    path: 'account/password',
    component: ChangePasswordComponent,
    canActivate: [AuthGuard]
  }, {
    path: 'login',
    component: LoginComponent,
    canActivate: [LoginGuard]
  }, {
    path: 'initiate-password-reset',
    component: InitiatePasswordResetComponent,
    canActivate: [LoginGuard]
  }, {
    path: 'password-reset',
    component: PasswordResetComponent,
    canActivate: [LoginGuard]
  }, {
    path: 'map',
    pathMatch: 'full',
    redirectTo: 'map/',
  }, {
    path: 'details',
    pathMatch: 'full',
    redirectTo: 'details/',
  }, {
    path: 'reports',
    pathMatch: 'full',
    redirectTo: 'reports/',
  }, {
    path: 'hull',
    pathMatch: 'full',
    redirectTo: 'hull/',
  }, {
    path: '',
    pathMatch: 'full',
    redirectTo: 'map/'
  }, {
    path: '**',
    component: NotFoundComponent,
    canActivate: [AuthGuard]
  }
];

export const AppRouting = RouterModule.forRoot(routes, {
    useHash: false,
    onSameUrlNavigation: 'reload'
});
