import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import * as moment from 'moment';
import {DateRange, Ranges} from '@app/helpers/ranges';

const LOCALE = {
  applyLabel: 'Apply',
  cancelLabel: 'Cancel',
  clearLabel: 'Clear',
  customRangeLabel: 'Custom range',
  daysOfWeek: moment.weekdaysMin(),
  firstDay: 1,
  format: 'MMM D, YYYY',
  monthNames: moment.monthsShort(),
  separator: ' - '
};

const DEFAULT_OPTIONS: DatePickerOptions = {
  alwaysShowCalendars: true,
  autoApply: false,
  customRangeDirection: true,
  drops: 'down',
  keepCalendarOpeningWithRange: true,
  maxDate: moment().endOf('day'),
  opens: 'right',
  // TODO: Validate against helpers/ranges:
  ranges: {
    'Last 24 Hours': [moment().startOf('day'), moment().endOf('day')],
    'Last 7 Days': [moment().subtract(6, 'days').startOf('day'), moment().endOf('day')],
    'Last 30 Days': [moment().subtract(29, 'days').startOf('day'), moment().endOf('day')],
    'This Month': [moment().startOf('month'), moment().endOf('day') ],
    'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
    // 'Yesterday': [moment().subtract(1, 'days').startOf('day'), moment().subtract(1, 'days').endOf('day')],
  },
  showCancel: true,
  showCustomRangeLabel: true,
  showRangeLabelOnInput: true
};


@Component({
  selector: 'app-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss']
})
export class DatePickerComponent implements OnInit {
  @Input() label: string;
  @Input() options: DatePickerOptions;
  @Input() selected: DateRange = Ranges.default();

  @Output() dateRange = new EventEmitter<DateRange>();

  locale = LOCALE;
  datePickerModel: DatePickerModel;

  private defaultOptions = DEFAULT_OPTIONS;
  private selectedPreset: DateRange;

  ngOnInit() {
    this.options = {
      ...this.defaultOptions,
      ...this.options
    };

    this.validateRangeDefinitions();
    this.setDatePickerModel();
  }

  onPresetClicked(event: { dates: moment.Moment[], label: string }) {
    this.selectedPreset = {
      start: event.dates[0].unix(),
      end: event.dates[1].unix(),
      name: event.label
    };
  }

  onDateUpdate(event: {[key: string]: moment.Moment}) {
    if (!event.startDate || !event.endDate) { return; }
    let result: DateRange;

    if (
      this.selectedPreset &&
      event.startDate.unix() === this.selectedPreset.start &&
      event.endDate.unix() === this.selectedPreset.end
    ) {
      // return up-to-date ranges instead of datepicker values when a preset was selected
      result = Object.assign(
        Ranges.byName(this.selectedPreset.name)
      );
    } else {
      // when selecting a single day by only clicking once instead of twice, start and end will be the same
      const start = event.startDate;
      const end = start.diff(event.endDate) === 0
        ? start.clone().endOf('day')
        : event.endDate;
      result = {
        start: this.translateLocal(start).unix(),
        end: this.translateLocal(end).unix()
      };
    }
    this.selectedPreset = null;
    this.dateRange.emit(result);
  }

  private validateRangeDefinitions() {
    for (const range of Object.keys(this.options.ranges)) {
      if (!Ranges.hasRange(range)) {
        throw new Error(`Error trying to initialize DatePicker with unknown range '${range}'`);
      }
    }
  }

  private setDatePickerModel() {
    if (this.selected.name) {
      this.datePickerModel = this.getInternalRange(this.selected.name);
    } else if (this.selected.start && this.selected.end) {
      this.datePickerModel = {
        start: this.translateUTC(moment.unix(this.selected.start)),
        end: this.translateUTC(moment.unix(this.selected.end))
      };
    }
  }

  private getInternalRange(name: string): DatePickerModel {
    const range = this.options.ranges[name];
    return { start: range[0], end: range[1] };
  }

  private translateLocal(date: moment.Moment): moment.Moment {
    return date.clone().add(date.utcOffset(), 'minutes').utc();
  }

  private translateUTC(date: moment.Moment): moment.Moment {
    return date.clone().subtract(date.utcOffset(), 'minutes');
  }
}

export interface DatePickerModel {
  start: moment.Moment;
  end: moment.Moment;
}

export interface DatePickerOptions {
  alwaysShowCalendars?: boolean;
  autoApply?: boolean;
  customRangeDirection?: boolean;
  dateLimit?: number;
  drops?: 'up' | 'down';
  keepCalendarOpeningWithRange?: boolean;
  maxDate?: moment.Moment;
  opens?: 'left' | 'right' | 'center';
  ranges?: {[key: string]: moment.Moment[]};
  showCancel?: boolean;
  showCustomRangeLabel?: boolean;
  showRangeLabelOnInput?: boolean;
}
