import * as moment from 'moment';

export interface DateRange {
  start: number;
  end: number;
  name?: string;
}

export class Ranges {

  private static map = {
    Last24Hours: 'Last 24 Hours',
    Last7Days:   'Last 7 Days',
    Last30Days:  'Last 30 Days',
    ThisMonth:   'This Month',
    LastMonth:   'Last Month'
  };

  static byName(name: string): DateRange {
    switch (name) {
      case Ranges.map.Last24Hours:
        return {
          name: Ranges.map.Last24Hours,
          start: moment.utc().subtract(1, 'day').unix(),
          end: moment.utc().unix()
        };
      default:
      case Ranges.map.Last7Days:
        return {
          name: Ranges.map.Last7Days,
          start: moment.utc().subtract(6, 'days').startOf('day').unix(),
          end: moment.utc().endOf('day').unix()
        };
      case Ranges.map.Last30Days:
        return {
          name: Ranges.map.Last30Days,
          start: moment.utc().subtract(29, 'days').startOf('day').unix(),
          end: moment.utc().endOf('day').unix()
        };
      case Ranges.map.ThisMonth:
        return {
          name: Ranges.map.ThisMonth,
          start: moment.utc().startOf('month').unix(),
          end: moment.utc().endOf('day').unix()
        };
      case Ranges.map.LastMonth:
        return {
          name: Ranges.map.LastMonth,
          start: moment.utc().subtract(1, 'month').startOf('month').unix(),
          end: moment.utc().subtract(1, 'month').endOf('month').unix()
        };
    }
  }

  static byApiKey(key: string): DateRange {
    return Ranges.byName(Ranges.map[key]);
  }

  static getApiKey(dateRange: DateRange): string {
    const apiKey = Object.keys(Ranges.map).find(key => Ranges.map[key] === dateRange.name);
    if (!apiKey) {
      throw new Error(`Error trying to find api key for range '${dateRange.name}'`);
    }
    return apiKey;
  }

  static default(): DateRange {
    return Ranges.byName(undefined);
  }

  static hasRange(name: string): boolean {
    const range = Ranges.byName(name);
    return range.name === name;
  }
}
