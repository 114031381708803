import { Injectable, OnDestroy } from '@angular/core';
import { VesselGroupsService } from '@app/services/vessel-groups/vessel-groups.service';
import { Observable, ReplaySubject, Subscription } from 'rxjs';
import { ApiService } from '@app/services/api/api.service';
import { MessageService } from 'primeng/api';

@Injectable({
  providedIn: 'root'
})
export class HullConditionService implements OnDestroy {

  private hullCondition;
  private hullConditionSubject: ReplaySubject<any>;

  private hullConditionReportSubject: ReplaySubject<any>;

  private hullConditionRemarksSubject: ReplaySubject<any>;
  private hullConditionRemarksPostLink: string | undefined = undefined;

  private hullConditionTracksSubject: ReplaySubject<any>;

  private hullConditionFilesSubject: ReplaySubject<any>;

  private subscriptions: Subscription[] = [];

  private selectedVessel: any | null = null;

  constructor(
    private apiService: ApiService,
    private vesselGroupsService: VesselGroupsService,
    private messageService: MessageService
  ) {
  }

  public getHullConditionObservable(): Observable<any> {
    if (!this.hullConditionSubject) {
      this.hullConditionSubject = new ReplaySubject<any>(1);

      this.subscriptions.push(
        this.vesselGroupsService.getSelectedVesselObservable().subscribe({
          next: selectedVessel => {
            this.setSelectedVessel(selectedVessel);
          }
        })
      );

    }
    return this.hullConditionSubject;
  }

  public getHullConditionReportObservable(): Observable<any> {
    if (!this.hullConditionReportSubject) {
      this.hullConditionReportSubject = new ReplaySubject<any>(1);
      this.getHullConditionObservable().subscribe({
        next: hullCondition => {
          this.startHullConditionReportUpdate();
        }
      });
    }
    return this.hullConditionReportSubject;
  }

  public getHullConditionRemarksObservable(): Observable<any> {
    if (!this.hullConditionRemarksSubject) {
      this.hullConditionRemarksSubject = new ReplaySubject<any>(1);
      this.getHullConditionObservable().subscribe({
        next: hullCondition => {
          this.startHullConditionRemarksUpdate();
        }
      });
    }
    return this.hullConditionRemarksSubject;
  }

  public getHullConditionTracksObservable(): Observable<any> {
    if (!this.hullConditionTracksSubject) {
      this.hullConditionTracksSubject = new ReplaySubject<any>(1);
      this.getHullConditionObservable().subscribe({
        next: hullCondition => {
          this.startHullConditionTracksUpdate();
        }
      });
    }
    return this.hullConditionTracksSubject;
  }

  public getHullConditionFilesObservable(): Observable<any> {
    if (!this.hullConditionFilesSubject) {
      this.hullConditionFilesSubject = new ReplaySubject<any>(1);
      this.getHullConditionObservable().subscribe({
        next: hullCondition => {
          this.startHullConditionFilesUpdate();
        }
      });
    }
    return this.hullConditionFilesSubject;
  }

  public addRemark(remarkText: string): void {
    if (!this.hullConditionRemarksPostLink) {
      return;
    }

    const body = {
      message: remarkText
    };

    this.apiService.post(this.hullConditionRemarksPostLink, body).subscribe({
      next: (responseData) => {
        this.startHullConditionRemarksUpdate();
      },
      error: (err: any) => {
        this.messageService.add({severity: 'error', summary: 'Error', detail: 'Add Message failed.'});
      }
    });
  }

  ngOnDestroy(): void {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  private setSelectedVessel(selectedVessel): void {
    if (selectedVessel && selectedVessel !== this.selectedVessel) {
      this.selectedVessel = selectedVessel;
      this.startHullConditionUpdate();
    }
  }

  private startHullConditionUpdate(): void {
    this.hullCondition = undefined;
    this.hullConditionSubject.next(this.hullCondition);

    const hrefHullCondition = this.apiService.resolveLink(this.selectedVessel._links[ 'fo:hull-condition' ].href);
    if (hrefHullCondition) {
      this.apiService.get(hrefHullCondition).toPromise().then(hullCondition => {
          this.hullCondition = hullCondition;
          this.hullConditionSubject.next(hullCondition);
        }, error => this.hullConditionSubject.next(undefined)
      );
    }
  }

  private startHullConditionReportUpdate(): void {
    this.hullConditionReportSubject.next(this.hullCondition);
  }

  private startHullConditionRemarksUpdate(): void {
    this.hullConditionRemarksSubject.next(undefined);
    this.hullConditionRemarksPostLink = undefined;

    if (this.hullCondition === undefined) {
      return;
    }

    const hrefHullConditionRemarks = this.apiService.resolveLink(this.hullCondition._links[ 'fo:remarks' ].href);
    if (hrefHullConditionRemarks) {
      this.apiService.get(hrefHullConditionRemarks).toPromise().then(hullConditionRemarks => {
          this.hullConditionRemarksPostLink = hullConditionRemarks._links[ 'fo:create-remark' ]?.href
          this.hullConditionRemarksSubject.next(hullConditionRemarks);
        }, error => this.hullConditionRemarksSubject.next(undefined)
      );
    }
  }

  private startHullConditionTracksUpdate(): void {
    this.hullConditionTracksSubject.next(undefined);

    if (this.hullCondition === undefined) {
      return;
    }

    const hrefHullConditionTracks = this.apiService.resolveLink(this.hullCondition._links[ 'fo:tracks' ].href);
    if (hrefHullConditionTracks) {
      this.apiService.get(hrefHullConditionTracks).toPromise().then(hullConditionTracks => {
          this.hullConditionTracksSubject.next(hullConditionTracks);
        }, error => this.hullConditionTracksSubject.next(undefined)
      );
    }
  }

  private startHullConditionFilesUpdate(): void {
    const retryDelay = 6000;

    this.hullConditionFilesSubject.next(undefined);

    if (this.hullCondition === undefined) {
      return;
    }

    const hrefHullConditionFiles = this.apiService.resolveLink(this.hullCondition._links[ 'fo:files' ].href);
    if (hrefHullConditionFiles) {
      this.apiService.get(hrefHullConditionFiles).toPromise().then(
        (hullConditionFiles) => {
          this.hullConditionFilesSubject.next(hullConditionFiles);
        },
        (error) => {
          this.messageService.add({severity: 'error', summary: 'Error', detail: 'Unable to get files list. Keep trying.'});
          setTimeout( () => {
            this.startHullConditionFilesUpdate()
          }, retryDelay);
      }
      );
    }
  }
}
