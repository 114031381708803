<div class="align-items-center flex flex-column gap-inner full-screen overflow-y-auto alignment"
     [ngClass]="{'spinner': submitting}">
  <div class="albis-logo">
    <form>
      <app-password [currentPasswordNeeded]="false" (submitted)="submit($event)"></app-password>
    </form>
    <p-dialog header="" [(visible)]="displayMessage" [modal]="true" [closable]="false" (onHide)="redirectToLogin()">
      {{ message }}
      <ng-template pTemplate="footer">
        <p-button (click)="displayMessage=false" label="Ok"></p-button>
      </ng-template>
    </p-dialog>
  </div>

  <div>
    <a routerLink="/login">Login</a>
  </div>

  <app-footer></app-footer>
</div>
