import {Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';

import {Report, ReportType} from '@app/services/api/api.models';
import {ReportsService} from '@app/services/reports/reports.service';
import {Subscription} from 'rxjs';
import {SortEvent} from 'primeng/api';

@Component({
  selector: 'app-reports-panel',
  templateUrl: './reports-panel.component.html',
  styleUrls: ['./reports-panel.component.scss']
})
export class ReportsPanelComponent implements OnInit, OnDestroy {
  private static monthYearFormat: Intl.DateTimeFormat = new Intl.DateTimeFormat('en', {
    month: 'short',
    year: 'numeric'
  });

  @ViewChild('dt') table;
  rowGroupMetadata: any = {};

  @Input() reportsService: ReportsService;
  reports: Report[] | undefined = undefined;
  selectedReports: Report[] = [];
  reportTypes: ReportType[] = [];
  private filteredReportsAvailable = true;
  private activeDownloads: (string | number)[] = [];

  private subscriptions: Subscription[] = [];

  private static reportDateComparatorAsc(a: Report, b: Report): number {
    const aDate = new Date(a.createdAt);
    aDate.setHours(0, 0, 0, 0);
    const bDate = new Date(b.createdAt);
    bDate.setHours(0, 0, 0, 0);
    return aDate < bDate ? -1 :
      aDate > bDate ? +1 : 0;
  }

  private static reportTypeComparatorAsc(a: Report, b: Report): number {
    return a.type.type < b.type.type ? -1 :
      a.type.type > b.type.type ? +1 :
        0;
  }

  private static sortReportsByDateAndType(reports: Report[], sortOrder: number): void {
    reports.sort((a, b) => {
      const date = ReportsPanelComponent.reportDateComparatorAsc(a, b) * sortOrder;
      return date !== 0 ? date : ReportsPanelComponent.reportTypeComparatorAsc(a, b);
    });
  }

  private static reportTypeReducer(accumulator: ReportType[], report: Report): ReportType[] {
    const reportType = accumulator.find(type => type.type === report.type.type);
    if (reportType === undefined) {
      accumulator.push({name: report.type.name, type: report.type.type});
    }
    return accumulator;
  }

  constructor() {
  }

  ngOnInit(): void {
    this.subscriptions.push(
      this.reportsService.initialize().subscribe({
        next: (reports) => {
          this.reports = reports;
          this.updateReports();
        }
      })
    );
  }

  ngOnDestroy(): void {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  isLoading(): boolean {
    return this.reports === undefined;
  }

  onFilter(event) {
    this.updateRowGroupMetaData(event.filteredValue);
    this.filteredReportsAvailable = event.filteredValue.length > 0;
  }

  customSort(event: SortEvent) {
    ReportsPanelComponent.sortReportsByDateAndType(event.data, event.order);
  }

  hasReports(): boolean {
    return Array.isArray(this.reports) && this.reports.length > 0;
  }

  hasFilterMatchingReports(): boolean {
    return this.hasReports() && this.filteredReportsAvailable;
  }

  hasSelectedReports(): boolean {
    return this.selectedReports.length !== 0;
  }

  downloadReports() {
    switch (this.selectedReports.length) {
      case 0:
        this.downloadMultipleReports(this.reports);
        break;

      case 1:
        this.downloadReport(this.selectedReports[0], true);
        break;

      default:
        this.downloadMultipleReports(this.selectedReports);
    }
  }

  downloadReport(report: Report, isAlsoCompilation: boolean = false) {
    this.registerActiveDownload(report.id);
    if (isAlsoCompilation) {
      this.registerActiveDownload('compilation');
    }
    this.reportsService.downloadReport(report).subscribe((id: number) => {
      this.removeActiveDownload(id);
      if (isAlsoCompilation) {
        this.removeActiveDownload('compilation');
      }
    });
  }

  hasActiveDownload(id: string | number): boolean {
    return this.activeDownloads.indexOf(id) > -1;
  }

  private downloadMultipleReports(reports: Report[]) {
    this.registerActiveDownload('compilation');
    for (const report of reports) {
      this.registerActiveDownload(report.id);
    }
    this.reportsService.downloadReports(reports).subscribe(() => {
      this.removeActiveDownload('compilation');
      for (const report of reports) {
        this.removeActiveDownload(report.id);
      }
    });
  }

  private registerActiveDownload(id: string | number) {
    this.activeDownloads.push(id);
  }

  private removeActiveDownload(id: string | number) {
    const index = this.activeDownloads.indexOf(id);
    if (index > -1) {
      this.activeDownloads.splice(index, 1);
    }
  }

  private updateReports() {
    if (this.reports === undefined) {
      return;
    }
    this.selectedReports = [];
    this.reportTypes = this.reports.reduce(ReportsPanelComponent.reportTypeReducer, []).sort();
    if (this.table) {
      const sortOrder = this.table._sortOrder;
      ReportsPanelComponent.sortReportsByDateAndType(this.reports, sortOrder);
      this.table.reset();
      this.table._sortOrder = sortOrder;
      this.table._sortField = 'createdAt';
    } else {
      ReportsPanelComponent.sortReportsByDateAndType(this.reports, -1);
    }
    this.updateRowGroupMetaData(this.reports);
  }

  private updateRowGroupMetaData(reports: Report[]) {
    this.rowGroupMetadata = {};
    for (let i = 0; i < reports.length; ++i) {
      const report = reports[i];
      const monthYear = ReportsPanelComponent.monthYearFormat.format(new Date(report.createdAt));
      if (this.rowGroupMetadata[monthYear] === undefined) {
        this.rowGroupMetadata[monthYear] = {index: i, size: 1};
      } else {
        ++this.rowGroupMetadata[monthYear].size;
      }
    }
  }
}
