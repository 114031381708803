<div *ngIf="hullConditionRemarks === undefined" class="spinner"></div>

<div *ngIf="hullConditionRemarks !== undefined" class="flex separator justify-content-between">
  <h2 class="pl-2">Blog</h2>
  <p-button *ngIf="!addMode" class="add-button" (click)="onAddBtnClick()" type="button">
    {{ addMode ? 'Dismiss' : 'Add' }}
  </p-button>
</div>

<div *ngIf="addMode" class="flex flex-column pl-2 pt-2">
  <textarea class="resize-none" [formControl]="addRemarkTextareaFormControl" placeholder="New blog entry." rows="3"
            type="text"></textarea>

  <div class="flex gap-inner justify-content-end pb-2 pt-2">
    <button pButton class="p-button-secondary" (click)="onCancelBtnClick()" type="reset">Cancel</button>
    <button pButton (click)="onSaveBtnClick()" [disabled]="!addRemarkTextareaFormControl.valid" type="submit">
      Save
    </button>
  </div>
</div>

<div *ngIf="hullConditionRemarks !== undefined" class="flex-auto overflow-y-auto pl-2 pr-3">
  <ul class="mt-0">
    <li *ngFor="let remark of hullConditionRemarks?.slice().reverse();" class="pb-2 pt-2 item-border-bottom">
      <div class="font-bold mb-1">
        {{ remark.createdAt_UTC_ISO | slice:0:10 }}, {{ remark.author }}
      </div>
      <div [innerText]="remark.message">
      </div>
    </li>

    <li *ngIf="hullConditionRemarks.length === 0" class="pt-2">
      No entries available.
    </li>
  </ul>
</div>
