import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Subscription} from 'rxjs';
import {ApiService} from '@app/services/api/api.service';
import {UserService} from '@app/services/user/user.service';
import {PasswordChange} from '@app/services/api/api.models';
import {HttpErrorResponse} from '@angular/common/http';
import {PasswordFormComponent} from '@app/components/password/password-form/password-form.component';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit, OnDestroy {
  @ViewChild(PasswordFormComponent) passwordFormComponent: PasswordFormComponent;

  submitting = false;
  displayMessage = false;
  message: string;

  private subscriptions: Subscription[] = [];
  private hrefChangePassword: string;

  constructor(private apiService: ApiService, private userService: UserService) {
  }

  ngOnInit(): void {
    this.subscriptions.push(
      this.userService.initialize().subscribe({
        next: () => {
          const links = this.userService.getUserDetail('_links');
          if (links && links['fo:change-password']) {
            this.hrefChangePassword = this.apiService.resolveLink(links['fo:change-password'].href);
          }
        }
      })
    );
  }

  ngOnDestroy(): void {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  submit(data: PasswordChange) {
    this.submitting = true;
    this.apiService.put(this.hrefChangePassword, data).subscribe(() => this.onSuccess(), (error) => this.onError(error));
  }

  isSubmitting(): boolean {
    return this.submitting;
  }

  private onSuccess() {
    this.passwordFormComponent.reset();
    this.submitting = false;
    this.message = 'Password successfully changed';
    this.displayMessage = true;
  }

  private onError(error: HttpErrorResponse) {
    this.submitting = false;
    if (error.status === 400) {
      this.message = error?.error?.message ?? 'An error occurred, please try again later or contact Albis support';
    } else if (error.status === 500) {
      this.message = 'An error occurred, please try again later or contact Albis support';
    }
    console.log(error);
    this.displayMessage = true;
  }
}
