import {Injectable} from '@angular/core';
import {
  AllGroups,
  IndividualStylesByTrackId,
  StylesByGroupType
} from '@app/components/hull-condition/hull-condition-tracks/series-builder-config';

@Injectable()
export class SeriesBuilderService {
  constructor() {
  }

  getSerieFromTrack(track): any {
    const groupStyles = StylesByGroupType[track.group] || {};
    const individualStyles = IndividualStylesByTrackId[track.abbreviation] || {};
    return {
      name: track.name + (track.unit ? ' [' + track.unit + ']' : ''),
      ...AllGroups,
      ...groupStyles,
      ...individualStyles,
      data: track.values.map((trackMeasurement) => this.trackMeasurementToSeriesData(trackMeasurement))
    };
  }

  protected trackMeasurementToSeriesData(trackMeasurement) {
    return [
      trackMeasurement.measuredAt_epoch_ms,
      trackMeasurement.value
    ];
  }
}
