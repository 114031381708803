import {Component, OnDestroy, OnInit} from '@angular/core';
import {VesselGroupsService} from '@app/services/vessel-groups/vessel-groups.service';
import {ApiService} from '@app/services/api/api.service';
import {Subscription} from 'rxjs';
import {HullConditionService} from '@app/services/hull-condition/hull-condition.service';

@Component({
  selector: 'app-hull-condition',
  templateUrl: './hull-condition.component.html',
  styleUrls: ['./hull-condition.component.scss']
})
export class HullConditionComponent implements OnInit, OnDestroy {
  selectedVessel: any | null = null;
  hullConditionReport: any | undefined;
  hullConditionRemarks: any | undefined;
  hullConditionTracks: any | undefined;
  hullConditionFiles: any | undefined;
  hullConditionFilesDownloadHref: string | undefined;

  private subscriptions: Subscription[] = [];

  constructor(
    private apiService: ApiService,
    private vesselGroupsService: VesselGroupsService,
    private hullConditionService: HullConditionService
  ) {
  }

  ngOnInit() {
    this.apiService.initialize().toPromise().then(() => {
      this.subscriptions.push(
        this.vesselGroupsService.getSelectedVesselObservable().subscribe({
          next: (selectedVessel) => {
            this.selectedVessel = selectedVessel;
          }
        }),
        this.hullConditionService.getHullConditionReportObservable().subscribe({
          next: (hullConditionReport) => {
            this.processHullConditionReport(hullConditionReport)
          }
        }),
        this.hullConditionService.getHullConditionRemarksObservable().subscribe({
          next: (hullConditionRemarks) => {
            this.processHullConditionRemarks(hullConditionRemarks)
          }
        }),
        this.hullConditionService.getHullConditionTracksObservable().subscribe({
          next: (hullConditionTracks) => {
            this.processHullConditionTracks(hullConditionTracks)
          }
        }),
        this.hullConditionService.getHullConditionFilesObservable().subscribe({
          next: (hullConditionFiles) => {
            this.processHullConditionFiles(hullConditionFiles)
          }
        })
      );
    });
  }

  ngOnDestroy() {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  getSelectedVesselId(): number {
    return this.vesselGroupsService.getSelectedVesselId();
  }

  addRemark($event: string): void {
    this.hullConditionService.addRemark($event);
  }

  private processHullConditionReport(hullConditionReport) {
    this.hullConditionReport = hullConditionReport;
  }

  private processHullConditionRemarks(hullConditionRemarks) {
    if (hullConditionRemarks === undefined) {
      this.hullConditionRemarks = undefined; // loading
    } else {
      const remarks = hullConditionRemarks?._embedded ? hullConditionRemarks._embedded['fo:remarks'] : undefined;
      this.hullConditionRemarks = Array.isArray(remarks) ? remarks : [];
    }
  }

  private processHullConditionTracks(hullConditionTracks) {
    if (hullConditionTracks === undefined) {
      this.hullConditionTracks = undefined; // loading
    } else {
      const tracks = hullConditionTracks?._embedded ? hullConditionTracks._embedded['fo:tracks'] : undefined;
      this.hullConditionTracks = Array.isArray(tracks) ? tracks : [];
    }
  }

  private processHullConditionFiles(hullConditionFiles) {
    if (hullConditionFiles === undefined) {
      this.hullConditionFiles = undefined; // loading
    } else {
      const files = hullConditionFiles?._embedded ? hullConditionFiles._embedded['fo:files'] : undefined;
      this.hullConditionFiles = Array.isArray(files) ? files : [];

      this.hullConditionFilesDownloadHref = hullConditionFiles?._links ? hullConditionFiles._links['fo:download'].href : undefined;
    }
  }
}
