import {Component, EventEmitter, Input, Output, ViewEncapsulation} from '@angular/core';
import {PasswordChange} from '@app/services/api/api.models';

@Component({
  selector: 'app-password',
  templateUrl: './password-form.component.html',
  styleUrls: ['./password-form.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PasswordFormComponent {
  static readonly minLength = 8;
  static readonly lowercaseRegExp = /(?=.*[a-z])/;
  static readonly uppercaseRegExp = /(?=.*[A-Z])/;
  static readonly numericRegExp = /(?=.*[0-9])/;
  static readonly specialRegExp = /(?=.*[!§$%&/(){}\[\]=?\\+*~#'\-_.:,;<>|])/;

  currentPassword = '';
  newPassword = '';
  newPasswordConfirmation = '';

  @Input() currentPasswordNeeded: boolean;
  @Output() submitted = new EventEmitter<PasswordChange>();

  constructor() {
  }

  submit() {
    this.submitted.emit({
      currentPassword: this.currentPassword.trim(),
      newPassword: this.newPassword.trim(),
      newPasswordConfirmation: this.newPasswordConfirmation.trim()
    });
  }

  areAllConditionsMet(): boolean {
    return ((this.currentPasswordNeeded && this.currentPassword.trim().length > 0) || !this.currentPasswordNeeded)
      && this.containsLowercase()
      && this.containsUppercase()
      && this.containsNumeric()
      && this.containsSpecial()
      && this.hasLength()
      && this.isConfirmed();
  }

  containsLowercase(): boolean {
    return PasswordFormComponent.lowercaseRegExp.test(this.newPassword);
  }

  containsUppercase(): boolean {
    return PasswordFormComponent.uppercaseRegExp.test(this.newPassword);
  }

  containsNumeric(): boolean {
    return PasswordFormComponent.numericRegExp.test(this.newPassword);
  }

  containsSpecial(): boolean {
    return PasswordFormComponent.specialRegExp.test(this.newPassword);
  }

  hasLength(): boolean {
    return this.newPassword.trim().length >= PasswordFormComponent.minLength;
  }

  isConfirmed(): boolean {
    const newTrimmed = this.newPassword.trim();
    const confirmTrimmed = this.newPasswordConfirmation.trim();
    return newTrimmed.length > 0 && newTrimmed.length === confirmTrimmed.length && newTrimmed === confirmTrimmed;
  }

  reset() {
    this.currentPassword = '';
    this.newPassword = '';
    this.newPasswordConfirmation = '';
  }
}
