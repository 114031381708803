import { Injectable } from '@angular/core';
import { HttpRequest, HttpResponse } from '@angular/common/http';

const maxAge = 4 * 3600 * 1000;
interface CacheEntry {
  url: string;
  response: HttpResponse<any>;
  insertedAt: number;
}

@Injectable({
  providedIn: 'root'
})
export class RequestCacheService {

  cache = new Map<string, CacheEntry>();
  whitelist: string[] = [
    '^/api/master-data',
    '^/api/vessels/\\d+$',
    '^/api/vessels/\\d+/reports',
    '^/api/vessels/\\d+/alerts'
    // TODO: can only be enabled if any kind of cachebreaking is implemented when a chart is edited and stored.
    // '^/api/vessels/\\d+/charts/\\d+\\?'
  ];

  private static getBaseUrl(): string {
    return (window !== undefined ) ?
      window.location.protocol + '//' + window.location.host
      : 'http://localhost';
  }

  get(req: HttpRequest<any>): HttpResponse<any> | undefined {
    const url = req.urlWithParams;
    const cached = this.cache.get(url);

    if (!cached) {
      return undefined;
    }

    const isExpired = cached.insertedAt < (Date.now() - maxAge);

    if (isExpired) {
      this.cache.delete(url);
      return undefined;
    }

    // HACK: Observables won't resolve if
    // we return the result immediatly
    setTimeout(() => {
      return cached.response;
    });
  }

  put(req: HttpRequest<any>, response: HttpResponse<any>): void {
    const url = req.urlWithParams;

    if (this.isWhitelisted(url)) {
      const entry = {url, response, insertedAt: Date.now()};
      this.cache.set(url, entry);
    }

    this.clearExpired();
  }

  clear(): void {
    this.cache.clear();
  }

  clearExpired(): void {
    const expireTime = Date.now() - maxAge;
    this.cache.forEach((cacheEntry, key) => {
      if (cacheEntry.insertedAt < expireTime) {
        this.cache.delete(key);
      }
    });
  }

  private isWhitelisted(url: string): boolean {
    const base = RequestCacheService.getBaseUrl();
    const urlObj = new URL(url, base);
    const pathAndSearchTupel = urlObj.pathname + urlObj.search;

    return this.whitelist.reduce<boolean>((prev: boolean, whitelistItem: string) => {
      return prev || pathAndSearchTupel.match( whitelistItem ) !== null;
    }, false);
  }

}
