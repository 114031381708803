<p-card>
  <div class="flex flex-column gap-grid">
    <span *ngIf="currentPasswordNeeded" class="p-float-label">
      <p-password  [feedback]="false" id="currentPassword" [(ngModel)]="currentPassword" [toggleMask]="true">
      </p-password>
      <label for="currentPassword">Enter current password</label>
    </span>

    <span class="p-float-label">
          <p-password [feedback]="false" id="newPassword" [(ngModel)]="newPassword" [toggleMask]="true"></p-password>
          <label for="newPassword">Enter new password</label>
      </span>

    <span class="p-float-label">
          <p-password [feedback]="false" id="newPasswordConfirmation" [ngClass]="{'ng-invalid ng-dirty no-match': !isConfirmed()}"
                      [(ngModel)]="newPasswordConfirmation" [toggleMask]="true">
          </p-password>
          <label for="newPasswordConfirmation">Confirm new password</label>
      </span>

    <div class="password-requirements">
      <ul>
        <li>
          <span [ngClass]="containsLowercase() ? 'albis-icons-check' : 'albis-icons-x'"></span>
          At least one lowercase character
        </li>
        <li>
          <span [ngClass]="containsUppercase() ? 'albis-icons-check' : 'albis-icons-x'"></span>
          At least one uppercase character
        </li>
        <li>
          <span [ngClass]="containsNumeric() ? 'albis-icons-check' : 'albis-icons-x'"></span>
          At least one numeric character
        </li>
        <li>
          <span [ngClass]="containsSpecial() ? 'albis-icons-check' : 'albis-icons-x'"></span>
          At least one special character:
        </li>
        <li>
          <span class="albis-icons-check" style="visibility: hidden"></span>
          {{ "!§$%&/(){}\[\]=?\\+*~#'-_.:,;<>|" }}
        </li>
        <li>
          <span [ngClass]="hasLength() ? 'albis-icons-check' : 'albis-icons-x'"></span>
          Minimum length of 8 characters
        </li>
      </ul>
    </div>

    <button pButton class="justify-content-center" (click)="submit()" [disabled]="!areAllConditionsMet()" type="submit">
      Apply
    </button>
  </div>
</p-card>
