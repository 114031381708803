import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Subscription} from 'rxjs';

import {ApiService} from '@app/services/api/api.service';
import {VesselGroupsService} from '@app/services/vessel-groups/vessel-groups.service';
import {VesselReportsService} from '@app/services/reports/vessel-reports.service';
import {ReportsPanelComponent} from '@app/components/reports/reports-panel/reports-panel.component';

@Component({
  selector: 'app-vessel-reports',
  templateUrl: './vessel-reports.component.html',
  styleUrls: ['./../reports.component.scss']
})
export class VesselReportsComponent implements OnInit, OnDestroy {
  @ViewChild(ReportsPanelComponent, {static: true}) reportsPanelComponent: ReportsPanelComponent;
  selectedVessel: any;
  private subscriptions: Subscription[] = [];

  constructor(
    private apiService: ApiService,
    private vesselGroupsService: VesselGroupsService,
    public reportsService: VesselReportsService
  ) {
  }

  ngOnInit() {
    this.apiService.initialize().toPromise().then(() => {
      this.subscriptions.push(
        this.vesselGroupsService.getSelectedVesselObservable().subscribe({
          next: (selectedVessel) => {
            this.selectedVessel = selectedVessel;
          }
        })
      );
    });
  }

  ngOnDestroy() {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  getSelectedVesselId(): number {
    return this.vesselGroupsService.getSelectedVesselId();
  }

  getVesselName() {
    return this.selectedVessel ? this.selectedVessel.name : ''
  }
}
