import {Component, OnDestroy, OnInit} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {Subscription} from 'rxjs';
import {VesselGroupsService} from '@app/services/vessel-groups/vessel-groups.service';
import {VESSEL_AWARE_ROUTEPARTS} from '@app/core/constants/constants';
import {MenuItem} from "primeng/api";

@Component({
  selector: 'app-nav-vessels',
  templateUrl: './nav-vessels.component.html',
  styleUrls: ['./nav-vessels.component.scss']
})

export class NavVesselsComponent implements OnInit, OnDestroy {
  private urlSegment: string;
  private subscriptions: Subscription[] = [];
  private items: MenuItem[] = [];

  constructor(
    private router: Router,
    private vesselGroupsService: VesselGroupsService
  ) {
  }

  ngOnInit() {
    this.subscriptions.push(
      this.vesselGroupsService.initialize().subscribe({
        next: () => {
          const vesselGroups = this.vesselGroupsService.getVesselGroups();
          this.items = [];
          vesselGroups.forEach((group) => {
            const vessels = group._embedded['fo:vessels'];
            if (vessels && vessels.length > 0) {
              const groupItem = {id: group.id, label: group.name, expanded: true, items: []};
              vessels.forEach((vessel) => {
                groupItem.items.push({
                  id: vessel.id,
                  label: vessel.name,
                  routerLink: `/${this.urlSegment}/${vessel.id}`
                })
              });
              this.items.push(groupItem);
            }
          });
        }
      }),

      this.router.events.subscribe((event) => {
        if (event instanceof NavigationEnd) {
          this.onUrlUpdate(this.router.url);
        }
      })
    );

    this.onUrlUpdate(this.router.url);
  }

  ngOnDestroy() {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  isLoading() {
    return this.vesselGroupsService.loading;
  }

  getItems(): MenuItem[] {
    return this.items;
  }

  private onUrlUpdate(url: string) {
    const selectedVesselId = parseInt(this.getUrlSegments(url)[1], 10);
    this.vesselGroupsService.setSelectedVesselId(selectedVesselId);
    const urlSegment = this.getUrlSegments(url)[0];
    this.urlSegment = VESSEL_AWARE_ROUTEPARTS.includes(urlSegment) ? urlSegment : 'map';
    this.updateRouterLink();
  }

  private updateRouterLink() {
    this.items.forEach((group) =>
      group.items.forEach((vessel) => vessel.routerLink = `/${this.urlSegment}/${vessel.id}`)
    );
    this.items = this.items.map((item) => item);  // create a new array so that angular updates the view
  }

  private getUrlSegments(url: string): string[] {
    return url.replace(/^\/+/g, '').split('/');
  }
}
