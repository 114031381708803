<div class="align-items-center content flex flex-column gap-inner" [ngClass]="{'spinner': isSubmitting()}">
  <h1>Change Password</h1>
  <form>
    <app-password #passwordFormComponent [currentPasswordNeeded]="true" (submitted)="submit($event)"></app-password>
  </form>
  <p-dialog [closable]="false" header="" [modal]="true" [(visible)]="displayMessage">
    {{ message }}
    <ng-template pTemplate="footer">
      <button pButton (click)="displayMessage=false" label="Ok" type="button"></button>
    </ng-template>
  </p-dialog>
</div>
