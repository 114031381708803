import {Injectable, OnDestroy} from '@angular/core';
import {BehaviorSubject, Subscription} from 'rxjs';

import {ApiService} from '@app/services/api/api.service';
import {ReportsService} from '@app/services/reports/reports.service';
import {UserService} from '@app/services/user/user.service';

@Injectable({
  providedIn: 'root'
})
export class FleetReportsService extends ReportsService implements OnDestroy {

  private subject: BehaviorSubject<any>;
  private subscriptions: Subscription[] = [];

  constructor(
    apiService: ApiService,
    private userService: UserService
  ) {
    super(apiService);
  }

  initialize() {
    if (!this.subject) {
      this.subject = new BehaviorSubject<any>(undefined);
      this.subscriptions.push(
        this.userService.initialize().subscribe({ next: (user) => {
            this.updateReports();
          }})
      );
    }
    return this.subject;
  }

  ngOnDestroy(): void {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  private updateReports(): void {
      const links = this.userService.getClientDetail('_links');
      if (links && links['fo:reports']) {
        const hrefReports = this.apiService.resolveLink(links['fo:reports'].href);
        this.apiService.get(hrefReports).toPromise().then(reports => {
            this.hrefDownloadReports = reports._links['fo:download'].href;
            this.subject.next(reports._embedded?.['fo:reports'] ?? []);
          }, error => this.subject.next([])
        );
      } else {
        this.subject.next([]);
      }
  }
}
