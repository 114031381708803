// src/app/services/auth/auth.service.ts
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Router } from '@angular/router';
import { environment } from 'environments/environment';

export interface IStorage {
  access_token: string;
  refresh_token: string;
  expires: number;
}

@Injectable()
export class AuthService {

  private refreshTimer: number;
  private headers: HttpHeaders;
  private tokenEndpoint: string;

  constructor(
    private router: Router,
    private http: HttpClient
  ) {
    this.tokenEndpoint = `${environment.api.url}${environment.api.tokenEndpoint}`;
    this.headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
      'Accept': 'application/json',
      'Authorization': 'Basic ' + btoa(`${environment.api.clientId}:${environment.api.clientSecret}`)
    });
    this.refreshToken();
  }

  public login(user: string, password: string): Promise<void> {

    const params = new HttpParams()
      .set('grant_type', 'password')
      .set('scope', 'read write')
      .set('username', user)
      .set('password', password);

    return this.http.post(
      this.tokenEndpoint,
      params,
      { headers: this.headers }
    ).toPromise().then(
      (response: any) => {
        this.setSessionStorage(response);
        this.setRefreshTimer();
      }
    );
  }

  public logout() {
    sessionStorage.clear();

    if (window.location.pathname !== '/login') {
      const queryString = window.location.pathname === '/' ? '' : '?returnUrl=' + encodeURIComponent(window.location.pathname);
      window.location.replace('/login' + queryString);
    }
  }

  public isAuthenticated(): boolean {
    const storage = this.getSessionStorage();
    return storage.access_token && storage.expires && storage.expires > Date.now();
  }

  public getToken(): string {
    return sessionStorage.getItem('access_token');
  }

  private refreshToken() {

    const storage = this.getSessionStorage();
    if (!storage.refresh_token) {
      return;
    }

    const params = new HttpParams()
      .set('grant_type', 'refresh_token')
      .set('scope', 'read write')
      .set('refresh_token', storage.refresh_token);

    return this.http.post(
      this.tokenEndpoint,
      params,
      { headers: this.headers }
    ).toPromise().then(
      (response: any) => {
        this.setSessionStorage(response);
        this.setRefreshTimer();
      }, (error) => {
        this.logout();
      }
    );
  }

  private getSessionStorage(): IStorage {
    return {
      access_token: sessionStorage.getItem('access_token'),
      refresh_token: sessionStorage.getItem('refresh_token'),
      expires: Number(sessionStorage.getItem('expires'))
    };
  }

  private setSessionStorage(storage: any) {
    sessionStorage.setItem('access_token', storage.access_token);
    sessionStorage.setItem('refresh_token', storage.refresh_token);
    sessionStorage.setItem('expires', String(Date.now() + storage.expires_in * 1000));
  }

  private setRefreshTimer(): void {
    if (this.refreshTimer) { clearTimeout(this.refreshTimer); }
    if (!this.isAuthenticated) { return; }
    const storage = this.getSessionStorage();
    const duration = storage.expires - Date.now() - 60 * 1000;
    this.refreshTimer = window.setTimeout(() => { this.refreshToken(); }, duration);
  }
}
