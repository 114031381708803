import {Injectable} from '@angular/core';
import {ReplaySubject, Subject} from 'rxjs';
import {ApiService} from '../api/api.service';

@Injectable()
export class UserService {

  private subject: ReplaySubject<any>;
  private user: { [key: string]: any } = {};
  private permissions: {[key: string]: boolean} = {};

  constructor(
    private apiService: ApiService
  ) { }

  public initialize(): Subject<any> {

    if (!this.subject) {
      this.subject = new ReplaySubject(1);
      this.apiService.initialize().subscribe({next: () => {
        this.extractPermissionsFromApiService();
        const href = this.apiService.getLink('fo:current-user');
        this.apiService.get(href).subscribe({next: (user) => {
          this.user = user;
          this.extractPermissionsFromUser();
          this.subject.next(this.user);
        }});
      }});
    }
    return this.subject;
  }

  public update(user: {[key: string]: any}) {

    // TODO: Do not send complete user or use PATCH instead
    user = Object.assign(this.user, user);

    const put = this.apiService.resolveLink(this.user._links['fo:user-update'].href);
    this.apiService.put(put, user).toPromise().then((result) => {
      this.subject.next(result);
    });
  }

  public getUserDetail(key: string): any {
    return this.user[key];
  }

  public getClientDetail(key: string): any {
    return this.user['_embedded'] !== undefined && this.user['_embedded'].client !== undefined
      ? this.user['_embedded'].client[key] : undefined;
  }

  public hasRole(role: string|string[]): boolean {
    role = role instanceof Array ? role : [role];
    return role.includes(this.getUserDetail('role'));
  }

  public hasPermission(permission: string|string[]): boolean {
    const permissions = permission instanceof Array ? permission : [permission];
    return permissions.reduce((accumulator, currentPermission) => {
      return accumulator && this.permissions[currentPermission];
    }, true);
  }

  private extractPermissionsFromApiService(): void {
    this.permissions['fo:hull-condition'] = this.apiService.getLink('fo:hull-condition') != null;
  }

  private extractPermissionsFromUser(): void {
    const links = this.getClientDetail('_links');
    this.permissions['fo:reports'] = links && links['fo:reports'] ? true : false;
  }
}
