import { Injectable } from '@angular/core';
import { HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { RequestCacheService } from '@app/services/cache/request-cache.service';

@Injectable({
  providedIn: 'root'
})
export class CachingInterceptorService implements HttpInterceptor {

  constructor(
    private cache: RequestCacheService
  ) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    const method = req.method;
    if (method !== 'GET') {
      return next.handle(req);
    }

    const cachedResponse = this.cache.get(req);
    if (cachedResponse) {
      return of(cachedResponse);
    }

    return next.handle(req).pipe(
      tap(event => {
        if (event instanceof HttpResponse) {
          this.cache.put(req, event);
        }
      })
    );
  }
}
