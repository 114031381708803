import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Subscription} from 'rxjs';

import {ApiService} from '@app/services/api/api.service';
import {ReportsPanelComponent} from '@app/components/reports/reports-panel/reports-panel.component';
import {FleetReportsService} from '@app/services/reports/fleet-reports.service';
import {UserService} from '@app/services/user/user.service';

@Component({
  selector: 'app-fleet-reports',
  templateUrl: './fleet-reports.component.html',
  styleUrls: ['./../reports.component.scss']
})
export class FleetReportsComponent implements OnInit, OnDestroy {
  @ViewChild(ReportsPanelComponent) reportsPanelComponent: ReportsPanelComponent;
  private clientName: string = '';
  private subscriptions: Subscription[] = [];

  constructor(
    private apiService: ApiService,
    public reportsService: FleetReportsService,
    private userService: UserService
  ) {
  }

  ngOnInit() {
    this.apiService.initialize().toPromise().then(() => {
      this.subscriptions.push(
        this.userService.initialize().subscribe({
          next: (user) => {
            this.clientName = this.userService.getClientDetail('name') ?? '';
          }
        })
      );
    });
  }

  ngOnDestroy() {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  getClientName() {
    return this.clientName;
  }
}
