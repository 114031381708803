import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {finalize} from 'rxjs/operators';

import {saveAs} from 'file-saver';

import {ApiService} from '@app/services/api/api.service';
import {Report} from '@app/services/api/api.models';
import {HttpResponse} from '@angular/common/http';

export abstract class ReportsService {

  protected hrefDownloadReports: string;

  private static save(response: HttpResponse<Blob>): void {
    saveAs(new File([response.body], ReportsService.getFilenameFromResponse(response), { type: response.body.type }));
  }

  private static getDownloadLinkForReport(report: Report): string {
    return report._links['fo:download'].href;
  }

  private static getFilenameFromResponse(response: HttpResponse<Blob>): string {
    const disposition: string = response.headers.get('content-disposition');
    const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
    let filename = 'download';

    if (disposition && disposition.indexOf('attachment') !== -1) {
      const matches = filenameRegex.exec(disposition);
      if (matches != null && matches[1]) {
        filename = matches[1].replace(/['"]/g, '');
      }
    }
    return filename;
  }

  constructor(
    protected apiService: ApiService,
  ) {
  }

  public abstract initialize(): BehaviorSubject<any>;

  public downloadReport(report: Report): Observable<number> {
    const subject = new Subject();
    this.apiService.download(ReportsService.getDownloadLinkForReport(report))
      .pipe(finalize(() => {
        subject.next(report.id);
        subject.complete();
      }))
      .subscribe((response: HttpResponse<Blob>) => {
        ReportsService.save(response);
      });
    return <Observable<number>>subject.asObservable();
  }

  public downloadReports(reports: Report[]): Observable<any> {
    const subject = new Subject();
    const reportIds = reports.map(report => report.id);
    const hrefDownload = this.apiService.resolveLink(this.hrefDownloadReports, {id: reportIds});
    this.apiService.download(hrefDownload)
      .pipe(finalize(() => {
        subject.next({});
        subject.complete();
      })).subscribe({
        next: (response: HttpResponse<Blob>) => {
          ReportsService.save(response);
        }
      });
    return <Observable<any>>subject.asObservable();
  }
}
