<div class="" [ngClass]="{ 'spinner': loading }">
  <div class="align-items-center flex justify-content-between pb-3 separator">
    <h3 class="m-0 pl-2">{{ config.title || '' }}</h3>
    <div *ngIf="!loading" class="align-items-center flex justify-content-between pr-3 w-4rem">
      <div (click)="resetChartSelection()"
           [ngClass]="{'albis-icons-zoom-in' : !zoomed, 'albis-icons-zoom-out' : zoomed}"
           pTooltip="Click and drag in the chart area to zoom in" tooltipPosition="bottom">
      </div>
      <p-menu #menu appendTo="body" [model]="options" [popup]="true"></p-menu>
      <p-button type="button" (click)="menu.toggle($event)" icon="albis-icons-menu-dots" styleClass="p-button-outlined">
      </p-button>
    </div>
  </div>

  <div class="overflow-x-auto pl-2 pr-3 pt-2">
    <highcharts-chart *ngIf="inited && !edit" (chartInstance)="chartInited($event)" class="block"
                      [Highcharts]="Highcharts" [options]="chartOptions">
    </highcharts-chart>
  </div>

  <div *ngIf="edit" class="chart-edit">
    <h3>Configure y-t-Chart</h3>
    <form [ngClass]="{'form-submitted': submitted}" (ngSubmit)="submitForm(editForm.form.valid)" #editForm="ngForm">
      <div class="field">
        <input pInputText class="w-full" id="title" name="title" [(ngModel)]="editModel.title"
               placeholder="Type headline" required type="text" #title="ngModel"/>
      </div>

      <div class="column-gap-5 formgrid grid">
        <div class="align-items-center col field flex form-option-track-1">
          <div class="form-option-icon"></div>
          <p-dropdown class="w-full" id="dataTrackId1" [group]="true" name="dataTrackId1"
                      [(ngModel)]="editModel.dataTrackId1" [options]="dataGroupModel" placeholder="Choose data"
                      [showClear]="true" #dataTrackId1="ngModel">
          </p-dropdown>
        </div>

        <div class="align-items-center col field flex form-option-track-2">
          <div class="form-option-icon"></div>
          <p-dropdown class="w-full" id="dataTrackId2" [group]="true" name="dataTrackId2"
                      [(ngModel)]="editModel.dataTrackId2" [options]="dataGroupModel" placeholder="Choose data"
                      [showClear]="true" #dataTrackId2="ngModel">
          </p-dropdown>
        </div>
      </div>

      <div class="column-gap-5 formgrid grid">
        <div class="align-items-center col field flex form-option-track-3">
          <div class="form-option-icon"></div>
          <p-dropdown class="w-full" id="dataTrackId3" [group]="true" name="dataTrackId3"
                      [(ngModel)]="editModel.dataTrackId3" [options]="dataGroupModel" placeholder="Choose data"
                      [showClear]="true" #dataTrackId3="ngModel">
          </p-dropdown>
        </div>

        <div class="align-items-center col field flex form-option-track-4">
          <div class="form-option-icon"></div>
          <p-dropdown class="w-full" id="dataTrackId4" [group]="true" name="dataTrackId4"
                      [(ngModel)]="editModel.dataTrackId4" [options]="dataGroupModel" placeholder="Choose data"
                      [showClear]="true" #dataTrackId4="ngModel">
          </p-dropdown>
        </div>
      </div>

      <div class="column-gap-5 formgrid grid">
        <div class="align-items-center col field flex form-option-track-5">
          <div class="form-option-icon"></div>
          <p-dropdown class="w-full" id="dataTrackId5" [group]="true" name="dataTrackId5"
                      [(ngModel)]="editModel.dataTrackId5" [options]="dataGroupModel" placeholder="Choose data"
                      [showClear]="true" #dataTrackId5="ngModel">
          </p-dropdown>
        </div>

        <div class="align-items-center col field flex form-option-track-6">
          <div class="form-option-icon"></div>
          <p-dropdown class="w-full" id="dataTrackId6" [group]="true" name="dataTrackId6"
                      [(ngModel)]="editModel.dataTrackId6" [options]="dataGroupModel" placeholder="Choose data"
                      [showClear]="true" #dataTrackId6="ngModel">
          </p-dropdown>
        </div>
      </div>

      <div class="flex gap-inner justify-content-end">
        <button pButton class="p-button-secondary" (click)="cancelForm()" type="reset">Cancel</button>
        <button pButton type="submit">Apply</button>
      </div>
    </form>
  </div>
</div>
