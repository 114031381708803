import {Injectable, OnDestroy} from '@angular/core';
import {BehaviorSubject, Subscription} from 'rxjs';

import {ApiService} from '@app/services/api/api.service';
import {VesselGroupsService} from '@app/services/vessel-groups/vessel-groups.service';
import {ReportsService} from '@app/services/reports/reports.service';

@Injectable({
  providedIn: 'root'
})
export class VesselReportsService extends ReportsService implements OnDestroy {

  private selectedVessel;

  protected subject: BehaviorSubject<any>;
  private subscriptions: Subscription[] = [];

  constructor(
    apiService: ApiService,
    private vesselGroupsService: VesselGroupsService
  ) {
    super(apiService);
  }

  initialize() {
    if (!this.subject) {
      this.subject = new BehaviorSubject<any>(undefined);

      this.subscriptions.push(
        this.vesselGroupsService.getSelectedVesselObservable().subscribe({
          next: selectedVessel => {
            this.setSelectedVessel(selectedVessel);
          }
        })
      );

    }
    return this.subject;
  }

  ngOnDestroy(): void {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  private setSelectedVessel(selectedVessel): void {
    if (selectedVessel && selectedVessel !== this.selectedVessel) {
      this.selectedVessel = selectedVessel;
      this.subject.next(undefined);

      const hrefReports = this.apiService.resolveLink(this.selectedVessel._links[ 'fo:reports' ].href);
      this.apiService.get(hrefReports).toPromise().then(reports => {
          this.hrefDownloadReports = reports._links[ 'fo:download' ].href;
          this.subject.next(reports._embedded?.['fo:reports'] ?? []);
        }, error => this.subject.next([])
      );
    }
  }
}
