/* eslint-disable */

/**
 * This file contains utility classes for testing
 * routing functionality. The contents were partially
 * taken from the official Angular docs on routing.
 */
export {
  ActivatedRoute,
  Router,
  RouterLink,
  RouterOutlet
} from '@angular/router';

import {Component, Directive, Injectable, Input} from '@angular/core';
import {convertToParamMap, Data, NavigationExtras, ParamMap} from '@angular/router';
// Only implements params and part of snapshot.paramMap
import {BehaviorSubject, of} from 'rxjs';

@Directive({
  selector: '[routerLink]',
  host: {
    '(click)': 'onClick()'
  }
})
export class RouterLinkStubDirective {
  @Input('routerLink') linkParams: any;
  navigatedTo: any = null;

  onClick() {
    this.navigatedTo = this.linkParams;
  }
}

@Directive({
  selector: '[routerLinkActiveOptions]'
})
export class RouterLinkActiveOptionsStubDirective {
  @Input('routerLinkActiveOptions') any;
}

@Component({selector: 'router-outlet', template: ''})
export class RouterOutletStubComponent { }

@Injectable()
export class RouterStub {
  events = of<Object>({})
  url = '/'
  navigate(commands: any[], extras?: NavigationExtras) { }
  navigateByUrl(url: any, extras: NavigationExtras) { }
}


@Injectable()
export class ActivatedRouteStub {

  // ActivatedRoute.data is Observable
  private dataSubject = new BehaviorSubject(this.testData);
  data = this.dataSubject.asObservable();

  // Test data
  private _testData: Data;
  get testData() { return this._testData; }
  set testData(data: Data) {
    this._testData = data;
    this.dataSubject.next(this._testData);
  }

  // ActivatedRoute.paramMap is Observable
  private paramSubject = new BehaviorSubject(convertToParamMap(this.testParamMap));
  paramMap = this.paramSubject.asObservable();

  // Test parameters
  private _testParamMap: ParamMap;
  get testParamMap() { return this._testParamMap; }
  set testParamMap(params: {}) {
    this._testParamMap = convertToParamMap(params);
    this.paramSubject.next(this._testParamMap);
  }

  private _testQueryParams: {} = {};
  get testQueryParams() { return of(this._testQueryParams); }
  set testQueryParams(params: {}) {
    this._testQueryParams = params;
  }

  // ActivatedRoute.snapshot.paramMap
  get snapshot() {
    return {
      paramMap: this.testParamMap,
      queryParams: this.testQueryParams
    };
  }
}
