import {Inject} from '@angular/core';
import {UserService} from '@app/services/user/user.service';
import {DateRange, Ranges} from '@app/helpers/ranges';
import {ReplaySubject, Subject} from 'rxjs';
import {SESSION_STORAGE, StorageService} from 'ngx-webstorage-service';

export class RangeService {

  private rangeSubject: ReplaySubject<DateRange> = new ReplaySubject<DateRange>(1);

  constructor(
    protected userService: UserService,
    @Inject(SESSION_STORAGE) protected storage: StorageService,
    private config: { storageRangeKey: string, userPropertyKey: string }
  ) {
    this.populateFromStorage();

    this.userService.initialize().subscribe({
      next: (user) => this.populateFromUser(user)
    });
  }

  public getRangeSubject(): Subject<DateRange> {
    return this.rangeSubject;
  }

  public setRange(dateRange: DateRange) {
    this.storage.set(this.config.storageRangeKey, dateRange);
    this.rangeSubject.next(dateRange);
  }

  private populateFromStorage() {
    if (this.storage.has(this.config.storageRangeKey)) {
      this.rangeSubject.next(this.storage.get(this.config.storageRangeKey));
    }
  }

  private populateFromUser(user) {
    // only populate from user if not already populated from storage
    if (!this.storage.has(this.config.storageRangeKey)) {
      const dateRange = Ranges.byApiKey(user[this.config.userPropertyKey]);
      this.storage.set(this.config.storageRangeKey, dateRange);
      this.rangeSubject.next(dateRange);
    }
  }
}
