import { Injectable } from '@angular/core';
import { Observable, Subscriber } from 'rxjs';

import * as mockData from './mock.data';

@Injectable()
export class MockService {

  constructor() { }

  public isMocked(path: string): boolean {
    const key = this.pathToKey(path);
    return Object.prototype.hasOwnProperty.call(mockData, key);
  }

  public getPartial(path: string): any {

    let result = {};

    for (const partial of Object.values(mockData['partials'])) {
      if (partial.expression.test(path)) {
        result = Object.assign(result, partial.result);
      }
    }

    return result;
  }

  public get(path: string): Observable<any> {

    const storage = JSON.parse(localStorage.getItem('apiMocks')),
          key = this.pathToKey(path);

    let data = mockData['_meta'];

    if (storage && Object.prototype.hasOwnProperty.call(storage, key)) {
      data = Object.assign(data, storage[key]);
    } else if (Object.prototype.hasOwnProperty.call(mockData, key)) {
      data = Object.assign(data, mockData[key]);
    }

    const observable = new Observable((observer: Subscriber<any>) => {
      setTimeout(() => {
        observer.next( data );
        observer.complete();
      });
    });

    return observable;
  }

  private pathToKey(path: string): string {
    path = path.replace(/[^\w]/g, '-');
    const pathArray = path.split('-');
    for (let i = 1; i < pathArray.length; i ++) {
      pathArray[i] = pathArray[i].charAt(0).toUpperCase() + pathArray[i].slice(1);
    }
    return pathArray.join('');
  }
}
