import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {File} from '@app/services/api/api.models';
import {FilesService} from '@app/services/hull-condition/files.service';

type DownloadItem = File | string;

@Component({
  selector: 'app-hull-condition-files',
  templateUrl: './hull-condition-files.component.html',
  styleUrls: ['./hull-condition-files.component.scss']
})
export class HullConditionFilesComponent implements OnChanges {
  @Input() hullConditionFiles: File[] | undefined = undefined;
  @Input() downloadHref: string | undefined = undefined;

  selectedFiles: File[] = [];
  private activeDownloads: DownloadItem[] = [];

  constructor(private filesService: FilesService) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['hullConditionFiles'] && Array.isArray(changes['hullConditionFiles'].currentValue)) {
      this.addGroupSortProperty();
      this.activeDownloads = [];
      this.selectedFiles = [];
    }
  }

  isLoading() {
    return this.hullConditionFiles === undefined;
  }

  hasFilesAvailable(): boolean {
    return this.hullConditionFiles && this.hullConditionFiles.length > 0;
  }

  hasSelectedFiles() {
    return this.selectedFiles.length > 0;
  }

  hasActiveDownload(file: DownloadItem): boolean {
    return this.activeDownloads.indexOf(file) > -1;
  }

  downloadFile(file) {
    this.registerActiveDownload(file);
    this.filesService.downloadFile(file).subscribe((file: File) => {
      this.removeActiveDownload(file);
    });
  }

  downloadFiles() {
    if (this.selectedFiles.length === 0) {
      this.downloadMultipleFiles(this.hullConditionFiles);
    } else {
      this.downloadMultipleFiles(this.selectedFiles);
    }
  }

  private addGroupSortProperty() {
    this.hullConditionFiles = this.hullConditionFiles.map((file: File) => {
      return {
        ...file,
        year: file.createdAt_ISO_UTC.substring(0, 4)
      };
    });
  }

  private downloadMultipleFiles(files: File[]) {
    const compilationFileHolder = 'compilation';
    this.registerActiveDownload(compilationFileHolder);
    for (const file of files) {
      this.registerActiveDownload(file);
    }
    this.filesService.downloadFiles(files, this.downloadHref).subscribe(() => {
      this.removeActiveDownload(compilationFileHolder);
      for (const file of files) {
        this.removeActiveDownload(file);
      }
    });
  }

  private registerActiveDownload(file: DownloadItem) {
    this.activeDownloads.push(file);
  }

  private removeActiveDownload(file: DownloadItem) {
    const index = this.activeDownloads.indexOf(file);
    if (index > -1) {
      this.activeDownloads.splice(index, 1);
    }
  }
}
