import { Injectable, Inject } from '@angular/core';
import { UserService } from '@app/services/user/user.service';
import { SESSION_STORAGE, StorageService } from 'ngx-webstorage-service';
import { RangeService } from '@app/services/ranges/range-service';

@Injectable({
  providedIn: 'root'
})
export class TrackRangeService extends RangeService {
  constructor(
    userService: UserService,
    @Inject(SESSION_STORAGE) storage: StorageService
  ) {
    super(userService, storage, {
      storageRangeKey: 'fo-strack-range',
      userPropertyKey: 'trackPeriod'
    });
  }
}
