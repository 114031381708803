<div class="h-full map-background" id="app-map" [ngClass]="{'spinner': isMapLoading() }">
  <app-map-ol *ngIf="!aisEnabled && !weatherEnabled" [parent]="thisComponent"></app-map-ol>
  <app-map-windy *ngIf="weatherEnabled" [parent]="thisComponent"></app-map-windy>
  <app-map-ais *ngIf="aisEnabled" [parent]="thisComponent"></app-map-ais>

  <div class="map-fullscreen-logo"><img class="h-5rem" src="/assets/img/logo-albis.svg" alt="albis logo"></div>

  <div class="map-overlay" id="map-overlay" [ngClass]="{'map-overlay-active': isLayerOpen(), 'spinner': isLoading() }">
    <div class="map-overlay-title">
      <h2>{{ selectedVessel ? selectedVessel.name : 'Loading ...' }}</h2>
      <h5 *ngIf="selectedVessel && selectedVessel.category === 'CTV'"> Latest passage day summary</h5>
      <div class="map-overlay-close" (click)="closeLayer()"></div>
    </div>

    <div class="overflow-x-hidden overflow-y-auto">
      <div *ngIf="!!selectedVessel?.pictureUrl" class="flex flex-column">
        <div class="bg-center bg-cover h-20rem"
             [ngStyle]="{'background-image': 'url(\'' + selectedVessel.pictureUrl + '\')'}">
        </div>
        <div class="align-self-end pr-2">
          <a class="color-ci-2-b text-xs" href="https://www.vesselfinder.com/?imo={{selectedVessel.imo}}">&copy;VesselFinder</a>
        </div>
      </div>

      <div class="map-overlay-content">
        <ul>
          <li *ngFor="let row of selectedVesselDetails.popupDataValues" class="separator"
              [ngClass]="{'row-outdated': !!row.outdated}">
            <div class="row-name"><span>{{ row.name }}</span></div>
            <div class="row-value"><span>{{ row.value }}{{ row.unit }}</span></div>
            <div class="row-time"><span>{{ row.measuredAt ? (row.measuredAt | utcString) : 'outdated' }}</span></div>
          </li>
        </ul>
        <div *ngIf="!!selectedVesselDetails.summaryDataValues?.length" class="mt-5">
          <h4 class="separator">Operational Summary ({{ datePickerSelected.start * 1000 | date: 'mediumDate': 'utc' }}
            - {{ datePickerSelected.end * 1000 | date: 'mediumDate': 'utc' }})</h4>
          <ul>
            <li *ngFor="let row of selectedVesselDetails.summaryDataValues" class="separator">
              <div class="row-name"><span>{{ row.name }}</span></div>
              <div class="row-value"><span>{{ row.value + ' ' + row.unit }}</span></div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>

  <div class="map-controls" id="map-controls" [ngClass]="{'weather-enabled' : weatherEnabled, 'ais-enabled': aisEnabled}">
    <app-date-picker *ngIf="datePickerSelected !== undefined && !aisEnabled && !weatherEnabled"
                     class="shadow map-control-item" (dateRange)="onDateRangeChanged($event)"
                     [options]="datePickerOptions" [selected]="datePickerSelected">
    </app-date-picker>

    <!-- TODO replace labels/tooltips via angular to get rid of one of the two divs when angular-container-media-query leaves beta -->
    <div class="with-labels">
      <p-toggleButton [ngModel]="fullScreen" offIcon="albis-icons-maximize" offLabel="Fullscreen"
                      (onChange)="toggleFullScreen($event.checked)" onIcon="albis-icons-minimize" onLabel="Fullscreen">
      </p-toggleButton>

      <!-- TODO change ngif to disabled with primeeng 17?-->
      <p-toggleButton *ngIf="!aisEnabled" [ngModel]="weatherEnabled" offIcon="albis-icons-cloud" offLabel="Weather"
                      (onChange)="toggleWeather($event.checked)" onIcon="albis-icons-cloud-off" onLabel="Weather">
      </p-toggleButton>

      <p-toggleButton *ngIf="!weatherEnabled" [ngModel]="aisEnabled" offIcon="albis-icons-antenna"
                      offLabel="AIS (free version)" (onChange)="toggleAis($event.checked)"
                      onIcon="albis-icons-antenna-off" onLabel="AIS (free version)">
      </p-toggleButton>

      <p-toggleButton *ngIf="!aisEnabled && !weatherEnabled" [ngModel]="routeEnabled"
                      offIcon="albis-icons-route" offLabel="Distance" (onChange)="toggleRoute($event.checked)"
                      onIcon="albis-icons-route-off" onLabel="Distance">
      </p-toggleButton>
    </div>
    <div class="icons-only">
      <p-toggleButton [ngModel]="fullScreen" offIcon="albis-icons-maximize" offLabel=""
                      (onChange)="toggleFullScreen($event.checked)" onIcon="albis-icons-minimize" onLabel=""
                      pTooltip="Fullscreen"
                      tooltipPosition="top">
      </p-toggleButton>

      <!-- TODO change ngif to disabled whith primeeng 17?-->
      <p-toggleButton *ngIf="!aisEnabled" [ngModel]="weatherEnabled" offIcon="albis-icons-cloud" offLabel=""
                      (onChange)="toggleWeather($event.checked)" onIcon="albis-icons-cloud-off" onLabel=""
                      pTooltip="Weather" tooltipPosition="top">
      </p-toggleButton>

      <p-toggleButton *ngIf="!weatherEnabled" [ngModel]="aisEnabled" offIcon="albis-icons-antenna" offLabel=""
                      (onChange)="toggleAis($event.checked)" onIcon="albis-icons-antenna-off" onLabel=""
                      pTooltip="AIS (free version)" tooltipPosition="top">
      </p-toggleButton>

      <p-toggleButton *ngIf="!aisEnabled && !weatherEnabled" [ngModel]="routeEnabled"
                      offIcon="albis-icons-route" offLabel="" (onChange)="toggleRoute($event.checked)"
                      onIcon="albis-icons-route-off" onLabel="" pTooltip="Distance" tooltipPosition="top">
      </p-toggleButton>
    </div>
  </div>
</div>
