<div class="flex flex-column gap-inner h-full" [ngClass]="{ 'spinner': isLoading() }">
  <div>
    <button pButton (click)="downloadReports()" [disabled]="!hasFilterMatchingReports() || hasActiveDownload('compilation')"
            [icon]="hasActiveDownload('compilation') ? 'albis-icons-spinner spin' : 'albis-icons-download'"
            [label]="hasSelectedReports() ? 'Download selected' : 'Download all'"></button>
  </div>

  <div class="overflow-hidden">
    <p-table #dt [value]="reports" [customSort]="true" dataKey="id" (onFilter)="onFilter($event)" [scrollable]="true"
             scrollHeight="flex" selectionMode="single" [(selection)]="selectedReports" sortField="createdAt"
             (sortFunction)="customSort($event)" [sortOrder]="-1">
      <ng-template pTemplate="header">
        <tr>
          <th class="w-2rem">
            <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
          </th>
          <th class="date" pSortableColumn="createdAt">
            Date
            <p-sortIcon field="createdAt"></p-sortIcon>
          </th>
          <th>
            Report Name
            <p-columnFilter display="menu" field="type.type" matchMode="in" [showMatchModes]="false"
                            [showOperator]="false" [showAddButton]="false">
              <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                <p-multiSelect [ngModel]="value" (onChange)="filter($event.value)" [options]="reportTypes"
                               optionLabel="name" optionValue="type" placeholder="Select Report Type"
                               [showHeader]=false>
                  <ng-template let-option pTemplate="item">
                    <span class="type type-{{option.type | lowercase}}">{{ option.name }}</span>
                  </ng-template>
                </p-multiSelect>
              </ng-template>
            </p-columnFilter>
          </th>
          <th class="text-center">Download</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-report let-rowIndex="rowIndex">
        <tr *ngIf="rowGroupMetadata[report.createdAt | date : 'MMM yyyy'].index === rowIndex">
          <td colspan="4"><span>{{ report.createdAt | date : 'MMMM yyyy' }}</span></td>
        </tr>
        <tr>
          <td>
            <p-tableCheckbox [value]="report"></p-tableCheckbox>
          </td>
          <td>{{ report.createdAt | date : 'MM-dd' }}</td>
          <td class="type type-{{report.type.type | lowercase}} white-space-nowrap">{{ report.name }}</td>
          <td class="relative text-center" [ngClass]="{ 'spinner': hasActiveDownload(report.id) }">
            <a class="albis-icons-download table-icon-link" disabled="hasActiveDownload(report.id)"
               (click)="downloadReport(report)"></a>
          </td>
        </tr>
      </ng-template>

      <ng-template pTemplate="emptymessage">
        <tr>
          <td colspan="4">No reports available</td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>
