<div *ngIf="datePickerModel" class="date-picker">
  <label *ngIf="label">{{label}}</label>

  <div class="date-picker-input">
    <span class="albis-icons-calendar"></span>
    <input type="text"
      readonly
      ngxDaterangepickerMd

      (datesUpdated)="onDateUpdate($event)"
      (rangeClicked)="onPresetClicked($event)"
      [(ngModel)]="datePickerModel"

      [locale]="locale"
      [ranges]="options.ranges"
      [alwaysShowCalendars]="options.alwaysShowCalendars"
      [autoApply]="options.autoApply"
      [customRangeDirection]="options.customRangeDirection"
      [dateLimit]="options.dateLimit"
      [drops]="options.drops"
      [keepCalendarOpeningWithRange]="options.keepCalendarOpeningWithRange"
      [maxDate]="options.maxDate"
      [opens]="options.opens"
      [showCancel]="options.showCancel"
      [showCustomRangeLabel]="options.showCustomRangeLabel"
      [showRangeLabelOnInput]="options.showRangeLabelOnInput"
      startKey="start"
      endKey="end" />
      <span class="albis-icons-chevron-down"></span>
  </div>
</div>
