import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {FormControl, Validators} from '@angular/forms';

@Component({
  selector: 'app-hull-condition-remarks',
  templateUrl: './hull-condition-remarks.component.html',
  styleUrls: ['./hull-condition-remarks.component.scss']
})
export class HullConditionRemarksComponent implements OnChanges {
  @Input() hullConditionRemarks: any | undefined = undefined;
  @Output() addRemarkEvent = new EventEmitter<string>();

  addMode: boolean = false;
  addRemarkTextareaFormControl: FormControl = new FormControl<string>(
    '',
    {
      validators: [
        Validators.required,
        Validators.maxLength(3000)
      ]
    }
  );

  constructor() {
  }

  ngOnChanges(changes: SimpleChanges) {
    this.addMode = false;
    this.addRemarkTextareaFormControl.setValue('');
  }

  onAddBtnClick() {
    this.addMode = true;
  }

  onCancelBtnClick() {
    this.addMode = false;
    this.addRemarkTextareaFormControl.setValue('');
  }

  onSaveBtnClick() {
    this.addMode = false;
    this.addRemarkEvent.emit(this.addRemarkTextareaFormControl.value);
  }
}
