<div *ngIf="isLoading()" class="spinner"></div>

<div *ngIf="hullConditionFiles !== undefined">
  <div class="separator">
    <h2 class="pl-2">Files</h2>
  </div>

  <div class="flex flex-column gap-inner pl-2 pr-3 pt-2">
    <div>
      <button pButton (click)="downloadFiles()" [disabled]="!hasFilesAvailable() || hasActiveDownload('compilation')"
              [icon]="hasActiveDownload('compilation') ? 'albis-icons-spinner spin' : 'albis-icons-download'"
              [label]="hasSelectedFiles() ? 'Download selected' : 'Download all'"></button>
    </div>

    <p-table dataKey="name" groupRowsBy="year" rowGroupMode="subheader" [scrollable]="true" scrollHeight="40rem"
             [(selection)]="selectedFiles" selectionMode="single" sortField="createdAt_ISO_UTC" sortOrder="-1"
             sortMode="single" [value]="hullConditionFiles">
      <ng-template pTemplate="header">
        <tr>
          <th class="w-2rem">
            <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
          </th>
          <th class="date">Date</th>
          <th class="filename">Filename</th>
          <th class="text-center">Download</th>
        </tr>
      </ng-template>

      <ng-template pTemplate="groupheader" let-file>
        <tr pRowGroupHeader>
          <td colspan="4">
            <span>{{ file.year }}</span>
          </td>
        </tr>
      </ng-template>

      <ng-template pTemplate="body" let-file>
        <tr>
          <td>
            <p-tableCheckbox [value]="file"></p-tableCheckbox>
          </td>
          <td>{{ file.createdAt_ISO_UTC | slice:5:10 }}</td>
          <td class="white-space-nowrap">{{ file.name }}</td>
          <td class="relative text-center" [ngClass]="{ 'spinner': hasActiveDownload(file) }">
            <a class="albis-icons-download table-icon-link" disabled="hasActiveDownload(file)"
               (click)="downloadFile(file)"></a>
          </td>
        </tr>
      </ng-template>

      <ng-template pTemplate="emptymessage">
        <tr>
          <td colspan="4">No files available</td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>
